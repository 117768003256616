import React from "react";


export function Cv() {
    return (
        <div className="c14 c28 doc-content">
            <div>
                <p className="c12 c17">
                    <span className="c3" />
                </p>
            </div>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 width="50%" viewBox="0 0 835 291" enable-background="new 0 0 835 291" xmlSpace="preserve">
<path fill="#FEFEFF" opacity="1.000000" stroke="none" 
	d="
M525.000000,292.000000 
	C350.038727,292.000000 175.577469,292.000000 1.058100,292.000000 
	C1.058100,195.060287 1.058100,98.120544 1.058100,1.090396 
	C279.211243,1.090396 557.422546,1.090396 835.816895,1.090396 
	C835.816895,97.999725 835.816895,194.999832 835.816895,292.000000 
	C732.453918,292.000000 628.976929,292.000000 525.000000,292.000000 
M85.890503,210.274414 
	C85.345146,210.224014 84.799789,210.173615 83.582771,210.061142 
	C84.552483,210.877289 84.988739,211.244461 85.643066,212.257599 
	C97.419044,234.718521 126.749733,237.656479 143.443253,221.469818 
	C153.468063,211.749359 157.162521,196.828369 152.193466,185.889908 
	C148.386688,177.509918 139.873352,173.131393 131.335068,175.162140 
	C123.128304,177.114014 117.871346,184.236557 117.963394,193.296936 
	C117.981995,195.128021 118.121979,196.960938 118.070251,198.789169 
	C117.826950,207.388977 112.277382,214.016510 103.865242,215.699646 
	C97.169823,217.039291 91.446823,214.701736 85.890503,210.274414 
M486.317688,119.128342 
	C483.376373,114.303482 479.820801,109.738304 477.644257,104.590118 
	C474.717316,97.666901 469.860535,96.238548 463.119019,96.863426 
	C460.003204,97.152229 459.185272,98.158768 459.198364,101.053482 
	C459.280304,119.193970 459.227905,137.335037 459.265686,155.475815 
	C459.268005,156.586624 459.556549,158.627289 459.888153,158.664490 
	C463.621826,159.083252 467.412811,159.463547 471.128754,159.149048 
	C471.828979,159.089783 472.723267,155.940689 472.750732,154.205078 
	C472.906525,144.349823 472.826172,134.490845 472.826172,124.633141 
	C480.299988,135.015228 486.770813,145.597183 493.347137,156.113159 
	C494.165649,157.421982 495.457855,159.223572 496.678009,159.347702 
	C500.704895,159.757401 504.799469,159.502090 508.857422,159.502090 
	C508.857422,138.239075 508.857422,117.855530 508.857422,97.362915 
	C504.458588,97.362915 500.418457,97.362915 495.814453,97.362915 
	C495.814453,109.532806 495.814453,121.341637 495.814453,134.593170 
	C492.255096,128.757858 489.498749,124.239075 486.317688,119.128342 
M417.825806,132.617661 
	C417.825806,129.933228 417.825806,127.248810 417.825806,124.564384 
	C425.343323,135.079605 431.814209,145.705139 438.446442,156.228989 
	C439.311157,157.601151 440.914246,159.223373 442.333923,159.391678 
	C446.074371,159.835083 449.902191,159.541656 453.781555,159.541656 
	C453.781555,138.376404 453.781555,117.850739 453.781555,97.440598 
	C449.294952,97.440598 445.250610,97.440598 440.855164,97.440598 
	C440.855164,109.612534 440.855164,121.339157 440.855164,134.236816 
	C439.322876,131.932449 438.403595,130.638855 437.574982,129.289581 
	C431.569641,119.510857 425.612457,109.702309 419.550171,99.959129 
	C418.830200,98.801979 417.633240,97.236794 416.544495,97.140785 
	C412.655151,96.797791 408.717163,97.006546 404.238464,97.006546 
	C404.238464,99.537506 404.238464,101.332130 404.238464,103.126755 
	C404.238434,118.949455 404.154114,134.772766 404.269135,150.594635 
	C404.341339,160.522583 402.170654,159.416260 413.793945,159.591888 
	C416.778687,159.637009 417.950470,158.664154 417.879456,155.568985 
	C417.711365,148.244431 417.825531,140.913406 417.825806,132.617661 
M185.951447,188.020889 
	C190.023911,199.792755 185.675415,208.539185 171.348526,215.493973 
	C173.635544,215.493973 174.697525,215.536148 175.755341,215.487686 
	C194.482147,214.629852 209.052979,201.295273 211.664093,182.638290 
	C214.015396,165.837494 202.659195,147.751450 187.183624,143.650360 
	C176.437988,140.802734 166.238754,145.587555 163.015915,154.988281 
	C159.707977,164.637299 164.331924,173.135986 174.670502,178.340881 
	C178.784622,180.412109 182.006165,184.256348 185.951447,188.020889 
M62.522739,102.585701 
	C59.026951,99.474709 54.504379,96.965195 52.254467,93.124382 
	C47.905125,85.699638 50.660320,78.195107 54.708744,70.932579 
	C52.604397,71.504097 50.853466,72.257584 49.409035,73.398956 
	C37.196831,83.048935 32.308002,95.681519 35.284103,110.892723 
	C37.911026,124.319214 46.173641,133.409882 58.922409,138.289169 
	C66.321426,141.120972 73.814133,141.537323 81.226250,138.174545 
	C89.638428,134.358063 94.129379,125.881966 92.214401,117.481026 
	C90.145111,108.403145 82.893005,102.997108 73.251053,103.179405 
	C69.946854,103.241875 66.636261,102.965652 62.522739,102.585701 
M107.985909,93.579430 
	C116.408508,84.987450 116.730087,76.444023 108.905594,66.543152 
	C103.956345,60.280533 102.128700,53.494072 106.021317,46.163807 
	C109.873100,38.910450 116.214218,35.489853 124.460381,35.683666 
	C125.771034,35.714470 127.083046,35.687981 129.199951,35.687981 
	C113.299583,25.836075 94.806465,28.644190 81.596062,42.251129 
	C70.738579,53.434517 69.034012,73.248970 77.774628,86.672752 
	C85.011848,97.787636 96.749596,100.702164 107.985909,93.579430 
M103.909256,172.881302 
	C105.228279,160.533920 99.592567,152.075256 88.740753,150.469406 
	C81.174858,149.349792 75.756630,153.164200 70.943787,158.518753 
	C66.020554,163.996094 59.869228,166.476410 52.517338,164.321777 
	C44.976036,162.111649 40.501831,156.883881 38.742947,149.302673 
	C38.310078,147.436890 38.122536,145.514191 37.667957,142.643600 
	C36.943729,144.577667 36.483929,145.621185 36.157539,146.704895 
	C31.435118,162.384705 36.728416,177.995361 51.169594,188.338364 
	C70.956474,202.510056 98.884583,191.913559 103.909256,172.881302 
M226.040665,140.778671 
	C235.285141,125.172440 233.898590,108.051933 222.338440,95.065254 
	C212.182755,83.656357 194.814423,79.841072 180.288345,86.124695 
	C172.455261,89.513092 167.256256,95.463554 166.260269,104.121910 
	C165.480133,110.903862 168.316727,116.521072 173.944427,120.437752 
	C181.118546,125.430702 188.213562,124.373917 195.390717,120.053787 
	C206.134918,113.586578 218.000427,117.002831 222.353043,128.014099 
	C223.700928,131.423981 223.430008,135.457916 224.064728,139.178070 
	C224.201859,139.981873 225.047073,140.664871 226.040665,140.778671 
M147.423035,40.813042 
	C137.593750,46.888889 130.841263,55.053108 129.573318,66.979973 
	C128.261581,79.318840 137.212784,88.876694 149.489594,88.206146 
	C157.793213,87.752609 163.102707,82.574768 165.975464,72.129013 
	C169.665924,58.709976 183.239578,53.449833 194.645752,61.176815 
	C197.173096,62.888920 199.208725,65.326851 201.496597,67.454597 
	C202.561249,60.510616 196.762604,49.589397 188.504517,43.747776 
	C175.741821,34.719669 162.219543,33.318939 147.423035,40.813042 
M747.794189,97.018005 
	C747.162598,98.495789 746.006104,99.965645 745.983643,101.452629 
	C745.825073,111.947533 745.899658,122.445953 745.899658,132.943253 
	C745.899719,141.582626 745.899658,150.221985 745.899658,159.196518 
	C750.747864,159.196518 755.153992,159.196518 760.180908,159.196518 
	C760.180908,150.153427 760.180908,141.438644 760.180908,132.871490 
	C767.725891,132.871490 774.645691,132.871490 782.041626,132.871490 
	C782.041626,141.773911 782.041626,150.360657 782.041626,159.134491 
	C786.923279,159.134491 791.312622,159.134491 795.717285,159.134491 
	C795.717285,138.314713 795.717285,117.906090 795.717285,97.417770 
	C791.000244,97.417770 786.633911,97.417770 781.799377,97.417770 
	C781.799377,105.265945 781.799377,112.700897 781.799377,120.180016 
	C774.373535,120.180016 767.431702,120.180016 759.830200,120.180016 
	C759.830200,112.418831 759.830200,104.866814 759.830200,96.994980 
	C755.693420,96.994980 752.214905,96.994980 747.794189,97.018005 
M655.643616,133.097336 
	C661.306458,133.070496 666.969543,133.071793 672.632019,133.010147 
	C680.810608,132.921127 679.735840,134.260895 680.093262,125.506561 
	C680.256653,121.505341 678.650818,120.789223 675.133606,120.913231 
	C668.367920,121.151764 661.587769,120.982719 654.825989,120.982719 
	C654.825989,116.723404 654.825989,113.105949 654.825989,108.900925 
	C663.917358,108.900925 672.817810,108.900925 681.683838,108.900925 
	C681.683838,104.608322 681.683838,100.917282 681.683838,96.983246 
	C668.724731,96.983246 656.242004,97.056198 643.761353,96.931236 
	C640.695312,96.900543 640.195068,98.370407 640.202087,100.919350 
	C640.251953,119.076378 640.271790,137.233810 640.183533,155.390549 
	C640.169556,158.275208 640.899719,159.645309 644.081055,159.612778 
	C656.073547,159.490067 668.068298,159.599548 680.061218,159.503510 
	C681.216187,159.494263 683.301147,158.761169 683.360413,158.196701 
	C683.686646,155.089966 684.050720,151.778702 683.221252,148.874817 
	C682.939819,147.889496 679.223999,147.545151 677.064636,147.497955 
	C669.658325,147.336075 662.246277,147.435822 654.826782,147.435822 
	C654.826782,142.268433 654.826782,137.955231 655.643616,133.097336 
M550.377319,147.453140 
	C543.752686,147.453140 537.128113,147.453140 530.299072,147.453140 
	C530.299072,142.459686 530.299072,137.898163 530.299072,132.923218 
	C538.660583,132.923218 546.756592,132.923218 555.098022,132.923218 
	C555.098022,128.784119 555.098022,125.065163 555.098022,120.930634 
	C546.607300,120.930634 538.507507,120.930634 530.299316,120.930634 
	C530.299316,116.782738 530.299316,113.057213 530.299316,108.803589 
	C539.457153,108.803589 548.348633,108.803589 557.794678,108.803589 
	C557.794678,105.329536 557.983948,102.352386 557.679688,99.426575 
	C557.588074,98.545410 556.132385,97.116249 555.288147,97.106560 
	C542.351868,96.958015 529.413452,97.003098 516.190308,97.003098 
	C516.190308,117.999680 516.190308,138.411133 516.190308,159.047089 
	C530.481628,159.047089 544.641846,159.047089 558.888611,159.047089 
	C558.888611,155.089355 558.888611,151.633102 558.888611,147.453400 
	C556.243286,147.453400 553.791443,147.453400 550.377319,147.453140 
M740.955994,114.440300 
	C737.741943,102.557961 729.019775,96.162155 715.801758,95.995094 
	C701.319580,95.812050 690.467224,103.019249 687.313049,116.117195 
	C685.864441,122.132828 685.571716,128.716202 686.209045,134.892029 
	C688.349487,155.631989 709.455383,166.916931 728.213379,157.681046 
	C735.666687,154.011292 740.094971,147.927078 742.002686,139.150406 
	C737.287659,138.002045 732.861755,136.924088 728.360413,135.827759 
	C727.867310,136.930786 727.519653,137.667084 727.205627,138.417511 
	C724.394104,145.137329 719.795227,148.356354 713.576660,147.948639 
	C707.772400,147.568100 703.310730,143.682724 701.448792,137.047455 
	C699.653320,130.649048 699.603943,124.211296 701.972900,117.913780 
	C704.618896,110.879974 709.802490,107.911278 717.227295,108.787552 
	C723.018494,109.471024 725.723328,113.387283 727.595581,118.834641 
	C732.089905,117.558807 736.349182,116.349716 740.955994,114.440300 
M352.690948,159.527634 
	C353.544647,158.684616 354.778900,157.992477 355.183655,156.971497 
	C356.647308,153.279465 357.754944,149.448273 359.137360,145.721680 
	C362.689117,136.147339 366.316101,126.600899 370.178680,116.337364 
	C375.350708,130.005890 380.153900,142.846100 385.119171,155.623306 
	C385.694061,157.102615 387.061584,159.217300 388.245819,159.346283 
	C392.434784,159.802444 396.703766,159.523956 401.766937,159.523956 
	C400.863892,156.861786 400.374878,155.165070 399.721741,153.534073 
	C393.306671,137.514694 386.866028,121.505539 380.428925,105.494987 
	C376.990906,96.943916 376.984131,97.104393 367.928192,96.906288 
	C364.613892,96.833771 363.074615,97.732597 361.846191,100.993027 
	C355.177765,118.691750 348.174866,136.264328 341.317993,153.892319 
	C340.699615,155.482025 340.300568,157.157013 339.568787,159.546204 
	C344.252747,159.546204 348.045776,159.546204 352.690948,159.527634 
M294.109985,119.778580 
	C294.849548,104.652267 288.237762,97.431000 273.141144,97.022545 
	C267.978516,96.882858 262.809235,96.981865 257.643005,96.979996 
	C247.637070,96.976379 247.634460,96.978951 248.281708,107.027954 
	C248.301620,107.337204 248.503281,107.634743 248.784576,108.363724 
	C250.225067,108.427780 251.845337,108.554306 253.466171,108.562225 
	C259.632263,108.592392 265.805145,108.406990 271.962891,108.638474 
	C278.600250,108.887978 282.363403,115.245056 278.640350,120.539108 
	C277.164337,122.637978 273.472321,124.008591 270.687286,124.196030 
	C263.299225,124.693237 255.855637,124.364746 248.222168,124.364746 
	C248.222168,133.588943 248.140030,141.913651 248.242798,150.236099 
	C248.373627,160.829987 246.360275,159.309433 257.916718,159.645782 
	C261.738892,159.757034 262.849457,158.418549 262.691315,154.749939 
	C262.435852,148.824371 262.622620,142.879730 262.622620,136.900253 
	C268.102173,136.580444 272.924255,136.523529 277.689423,135.973495 
	C287.210754,134.874481 292.133240,130.105499 294.109985,119.778580 
M334.081207,147.410385 
	C326.670105,147.410385 319.258972,147.410385 311.311096,147.410385 
	C311.311096,130.137695 311.311096,113.726151 311.311096,96.969978 
	C307.767029,96.969978 304.768982,97.162247 301.806610,96.919464 
	C298.394989,96.639877 297.112244,97.740379 297.142609,101.345589 
	C297.294220,119.340385 297.277435,137.337631 297.150299,155.332809 
	C297.126953,158.640640 298.272583,159.683243 301.496735,159.642609 
	C312.492584,159.504028 323.491333,159.568771 334.488800,159.605148 
	C336.867218,159.613007 339.358521,159.359924 338.569000,156.279877 
	C337.787048,153.229172 341.864014,147.994263 334.081207,147.410385 
M581.584412,157.701706 
	C584.933472,158.316757 588.271606,159.001663 591.634705,159.526901 
	C594.899536,160.036804 595.955688,158.531235 595.911682,155.264297 
	C595.752319,143.433350 595.848755,131.598953 595.848694,119.765869 
	C595.848633,112.305321 595.848694,104.844772 595.848694,96.997696 
	C585.588257,96.997696 576.121826,96.924187 566.660889,97.117241 
	C565.765015,97.135513 564.439331,98.695396 564.136292,99.771423 
	C563.701233,101.315826 564.167847,103.088959 563.991516,104.736839 
	C563.648926,107.938194 564.769226,109.272026 568.158813,109.070564 
	C572.444580,108.815842 576.756958,109.009041 581.513062,109.009041 
	C581.513062,125.248871 581.513062,141.037613 581.584412,157.701706 
M632.844543,123.499977 
	C632.844543,114.744926 632.844543,105.989876 632.844543,96.992043 
	C623.414368,96.992043 614.437134,97.143990 605.469421,96.916435 
	C601.935852,96.826775 600.498596,97.955215 601.018250,101.447372 
	C601.066711,101.773285 601.025391,102.112709 601.024414,102.445847 
	C601.005127,109.006203 601.005066,109.006386 607.357361,109.007034 
	C610.981079,109.007408 614.604797,109.007095 618.504761,109.007095 
	C618.504761,122.867813 618.374268,136.181763 618.544128,149.491867 
	C618.692139,161.084518 616.034851,159.308624 628.914978,159.585831 
	C632.003235,159.652313 632.937439,158.476990 632.897766,155.481308 
	C632.760742,145.155792 632.844727,134.827332 632.844543,123.499977 
M429.308655,205.980103 
	C431.534424,211.006851 435.634857,212.689789 440.727081,211.862076 
	C447.598663,210.745132 448.651123,205.198807 449.270935,198.954025 
	C454.344147,202.523529 450.290588,207.686478 453.322723,212.787460 
	C458.025696,209.141815 454.420685,203.493286 456.760742,199.810577 
	C458.047180,199.957535 459.435547,200.116150 461.148895,200.311890 
	C461.148895,204.277451 461.148895,207.883377 461.148895,211.489319 
	C461.642059,211.735229 462.135223,211.981155 462.628418,212.227081 
	C463.418884,211.036041 464.701050,209.920029 464.896576,208.638229 
	C465.293610,206.035217 464.187836,201.570419 465.311523,200.989975 
	C470.510071,198.304657 466.451294,196.711884 464.287872,194.400391 
	C466.829437,193.932190 468.605316,193.605042 470.459595,193.263458 
	C465.911499,188.584915 462.531830,189.673553 459.997559,196.735504 
	C458.657257,196.463043 457.279419,196.182953 454.641846,195.646790 
	C457.298645,194.005630 458.664429,193.161942 460.414978,192.080582 
	C452.400757,186.726425 452.957397,196.625977 450.090179,197.376938 
	C446.692719,194.832581 444.323853,191.919098 441.356842,191.051941 
	C432.742157,188.534180 427.742889,194.574615 429.308655,205.980103 
M362.989990,211.781326 
	C366.463837,211.724289 369.981506,211.993683 373.401306,211.534302 
	C378.635010,210.831223 380.352600,206.368149 377.533203,201.993347 
	C376.981934,201.137985 376.868347,199.601273 377.228058,198.628464 
	C378.648438,194.786957 377.042511,191.433624 372.970032,191.061172 
	C369.254700,190.721375 365.483795,190.989166 360.968933,190.989166 
	C361.375488,198.197891 361.751831,204.871292 362.989990,211.781326 
M619.212036,193.535156 
	C616.752991,190.174011 613.136536,189.883942 609.797607,191.024521 
	C607.840698,191.692993 605.357239,193.979385 605.161560,195.762985 
	C604.962769,197.575470 606.935913,200.068939 608.609070,201.506149 
	C609.986694,202.689514 612.389465,202.599869 614.194519,203.398361 
	C615.113770,203.805023 615.717590,204.924774 616.463989,205.722153 
	C615.559082,206.552887 614.637451,208.083405 613.752869,208.062286 
	C612.130493,208.023575 610.509216,207.121155 608.926331,206.482391 
	C607.453552,205.888062 606.031372,205.168182 604.586853,204.503571 
	C604.337769,206.571579 604.573486,207.552689 605.080261,208.364670 
	C607.648682,212.479935 611.880493,212.925842 615.625366,211.513306 
	C617.854736,210.672424 620.378662,207.580536 620.652954,205.251328 
	C621.146790,201.056534 617.404724,199.702271 613.790710,198.809052 
	C612.030579,198.374039 610.406250,197.389587 608.125000,196.393967 
	C611.083130,193.541122 613.115051,193.331528 615.583496,195.600342 
	C616.469238,196.414429 618.087646,196.431427 619.371643,196.812210 
	C619.454346,195.939728 619.537109,195.067230 619.212036,193.535156 
M557.475830,210.775131 
	C558.798706,211.163162 560.121582,211.551208 561.844055,212.056473 
	C561.844055,204.683014 561.844055,197.971466 561.844055,191.213943 
	C555.582886,189.827621 559.362061,195.437683 557.229248,196.389526 
	C554.201355,196.851227 549.952637,197.211426 549.042236,199.144119 
	C547.718567,201.954254 547.976562,206.197327 549.092773,209.273117 
	C550.183411,212.278122 553.573059,212.878113 557.475830,210.775131 
M527.914124,201.055527 
	C527.737915,197.406281 525.279541,196.290329 522.198608,196.193802 
	C519.139160,196.097916 516.238342,196.579987 515.148132,200.752182 
	C518.207092,200.455246 520.924500,200.191467 523.641968,199.927689 
	C523.721436,200.534393 523.800903,201.141098 523.880371,201.747803 
	C522.018616,202.211578 519.815918,202.211563 518.385803,203.262695 
	C516.786926,204.437927 515.888794,206.566422 514.685669,208.280075 
	C516.547424,209.515442 518.275574,211.397125 520.305908,211.816696 
	C522.654236,212.301971 525.268555,211.500214 527.938782,211.240570 
	C527.938782,208.176270 527.938782,205.060501 527.914124,201.055527 
M394.736237,203.546432 
	C394.562622,202.904633 394.351776,202.270294 394.221436,201.619827 
	C393.421967,197.629471 390.618988,195.981995 386.965363,196.301636 
	C382.903015,196.657028 380.911560,199.471008 380.706635,203.462463 
	C380.512085,207.250397 381.461090,210.590302 385.581177,211.688812 
	C389.221436,212.659409 392.356262,211.863327 394.051270,208.304153 
	C391.598846,204.303329 386.639160,213.036804 385.045715,205.855484 
	C388.377716,205.321854 391.526306,204.817581 394.736237,203.546432 
M505.162109,202.073654 
	C504.478607,197.865021 501.686798,195.983566 497.762451,196.281097 
	C493.653870,196.592606 491.694580,199.546021 491.434113,203.425613 
	C491.182373,207.175003 492.266327,210.554947 496.318817,211.687347 
	C499.957214,212.704025 503.144348,211.906036 504.613983,207.510727 
	C502.990417,207.443985 501.587555,208.283173 500.146606,208.354675 
	C498.700592,208.426453 497.216644,207.732880 495.749298,207.374725 
	C495.870850,206.672791 495.992371,205.970871 496.113922,205.268936 
	C498.354980,205.268936 500.635040,205.509766 502.818939,205.156219 
	C503.756500,205.004440 504.494202,203.618042 505.162109,202.073654 
M316.528778,194.410553 
	C322.767517,194.403564 322.767517,194.403564 322.520721,191.228470 
	C318.098602,191.228470 313.687195,191.228470 309.234650,191.228470 
	C309.234650,198.137070 309.234650,204.733093 309.234650,211.737595 
	C316.336731,211.506927 311.151978,205.193527 314.486328,202.755020 
	C316.499664,202.755020 319.114441,202.755020 322.170380,202.755020 
	C321.130066,196.518372 315.849426,200.904434 312.846924,197.674622 
	C313.616913,196.765549 314.610535,195.592468 316.528778,194.410553 
M340.032227,207.373566 
	C341.870453,200.409958 337.704742,195.050201 331.437500,196.341904 
	C327.757477,197.100388 325.605408,199.479248 325.196014,203.107864 
	C324.797760,206.637375 325.901733,209.716446 329.465485,211.227859 
	C333.707275,213.026871 337.179260,211.923981 340.032227,207.373566 
M692.351379,211.173569 
	C698.384033,208.671417 697.889587,199.664932 692.661194,197.148331 
	C689.346130,195.552689 686.147217,195.795120 683.501648,198.342651 
	C680.701477,201.039032 680.218262,204.488922 681.976685,207.894897 
	C684.020569,211.853775 687.492126,212.772339 692.351379,211.173569 
M652.734070,211.992645 
	C655.660461,211.724121 658.586853,211.455597 661.782837,211.162323 
	C661.782837,206.646027 661.782837,201.886734 661.782837,197.127426 
	C661.404846,196.822617 661.026855,196.517792 660.648865,196.212982 
	C659.757446,197.274750 658.370667,198.220459 658.088440,199.424988 
	C657.643677,201.322906 658.299194,203.489655 657.803345,205.360886 
	C657.453064,206.682602 655.968201,207.703674 654.989868,208.858963 
	C654.087952,207.703171 652.589233,206.633804 652.406738,205.373825 
	C652.009827,202.632904 652.284180,199.794785 652.284180,195.921341 
	C650.378113,196.933167 648.669678,197.255020 648.312805,198.128845 
	C646.584045,202.361740 648.676392,209.711975 652.734070,211.992645 
M624.780457,209.599121 
	C626.055969,210.333710 627.250671,211.421875 628.622437,211.735062 
	C632.392273,212.595779 635.620667,211.483795 637.398865,207.874329 
	C639.090088,204.441223 638.797058,200.975082 635.879822,198.300797 
	C632.995361,195.656464 629.575806,195.480042 626.235962,197.497421 
	C622.627441,199.677063 622.006287,203.682190 624.780457,209.599121 
M717.566223,206.950333 
	C714.904968,206.958008 714.404541,208.727676 716.394592,209.696976 
	C719.003113,210.967529 722.257812,211.598022 725.131775,211.343399 
	C726.539551,211.218689 728.497742,208.579559 728.727844,206.878403 
	C728.888062,205.693771 726.744873,203.832962 725.244568,202.885834 
	C723.583984,201.837524 721.483459,201.486099 719.574524,200.831177 
	C719.671265,200.379196 719.767944,199.927216 719.864624,199.475235 
	C722.546143,199.782074 725.227661,200.088928 727.909180,200.395782 
	C728.046753,199.621124 728.184326,198.846451 728.321899,198.071777 
	C725.794495,197.450455 723.146057,196.068756 720.777466,196.445663 
	C718.843933,196.753326 717.244629,199.161758 715.497131,200.638779 
	C716.942871,202.190323 718.202271,204.001633 719.891602,205.213608 
	C721.317322,206.236420 723.241882,206.563828 724.945679,207.199036 
	C724.704773,207.848724 724.463867,208.498413 724.222900,209.148102 
	C722.263550,208.415283 720.304138,207.682465 717.566223,206.950333 
M401.630035,199.315048 
	C404.043762,199.740601 406.457489,200.166153 408.871216,200.591705 
	C409.042206,199.766388 409.213196,198.941071 409.384155,198.115738 
	C406.725586,197.498795 403.991821,196.210159 401.434326,196.496384 
	C399.671448,196.693695 398.152649,199.071411 396.524750,200.474655 
	C397.906738,202.063019 399.074615,203.946747 400.735291,205.150635 
	C402.185944,206.202240 404.191437,206.488449 405.951813,207.112808 
	C405.801392,207.712540 405.650940,208.312256 405.500519,208.911987 
	C402.494781,208.284302 399.489075,207.656631 396.483337,207.028961 
	C396.249573,207.696167 396.015808,208.363373 395.782043,209.030594 
	C398.270996,210.030243 400.688934,211.540558 403.267059,211.899277 
	C406.434052,212.339966 409.832428,210.961685 409.707825,207.392380 
	C409.646179,205.626740 406.662354,203.873260 404.846283,202.291519 
	C403.707581,201.299683 402.281921,200.637268 401.630035,199.315048 
M701.487488,211.769791 
	C702.031067,211.102524 702.949036,210.484375 703.044250,209.758286 
	C703.280151,207.960968 702.728638,205.982010 703.262390,204.323502 
	C703.830505,202.558334 705.282959,201.077789 706.349243,199.472977 
	C707.200867,201.020737 708.503601,202.488251 708.796509,204.135376 
	C709.217773,206.503677 708.904114,209.002731 708.904114,212.666290 
	C710.880066,211.451294 712.735229,210.881348 712.784424,210.183823 
	C713.028687,206.725830 713.269104,203.126999 712.515869,199.803299 
	C712.180847,198.324936 709.595276,196.569031 707.868774,196.373474 
	C705.102478,196.060120 702.201050,196.940353 699.060791,197.355179 
	C699.060791,200.042740 698.865967,202.863159 699.125061,205.641235 
	C699.317749,207.708130 700.098755,209.720169 701.487488,211.769791 
M533.481873,211.769455 
	C534.019958,211.099030 534.928772,210.476517 535.022888,209.748703 
	C535.255188,207.951538 534.702698,205.974106 535.234985,204.316116 
	C535.799622,202.557602 537.251831,201.084045 538.316345,199.486038 
	C539.171753,201.031021 540.480286,202.494949 540.774658,204.140305 
	C541.197815,206.505615 540.882568,209.003006 540.882568,212.681931 
	C542.845947,211.459396 544.714050,210.877289 544.764404,210.166397 
	C545.009216,206.711319 545.239624,203.113831 544.481506,199.793671 
	C544.144104,198.315872 541.557800,196.564240 539.831421,196.370590 
	C537.060059,196.059723 534.155090,196.939911 531.039795,197.352097 
	C531.039795,200.397232 530.863770,203.546661 531.109192,206.662888 
	C531.245300,208.390198 532.085999,210.062042 533.481873,211.769455 
M479.125610,197.480331 
	C475.843750,200.392548 475.333771,203.922134 476.905304,207.850220 
	C478.298828,211.333282 481.185883,212.365189 484.577942,211.928040 
	C487.534424,211.547028 489.817474,210.152420 489.893768,205.772537 
	C488.253723,206.389191 487.087677,206.970032 485.853333,207.253952 
	C484.459320,207.574615 483.003906,207.628403 481.575226,207.798264 
	C481.438202,205.458206 481.164459,203.106583 481.359741,200.794617 
	C481.381042,200.542557 484.117676,200.344254 485.573975,200.439758 
	C486.955414,200.530365 488.309387,201.039642 490.706360,201.610229 
	C487.561523,195.823486 484.011414,195.411667 479.125610,197.480331 
M587.523376,211.730026 
	C588.086243,210.403198 589.067810,209.097031 589.134460,207.745728 
	C589.344788,203.484344 589.212036,199.206039 589.212036,195.045853 
	C594.872437,194.016159 594.872437,194.016159 594.837341,191.215942 
	C589.603699,191.215942 584.370056,191.215942 579.136353,191.215942 
	C578.957703,191.752609 578.778992,192.289276 578.600281,192.825943 
	C580.552856,193.588425 582.505432,194.350891 585.041443,195.341187 
	C585.041443,199.099335 584.914734,203.393814 585.114868,207.673050 
	C585.178955,209.044647 586.107117,210.375870 587.523376,211.730026 
M672.406921,211.182800 
	C672.474182,210.702942 672.776978,209.970398 672.573608,209.780823 
	C669.041077,206.488190 667.922058,203.161163 671.938232,199.358002 
	C672.311218,199.004807 671.970337,197.583221 671.605225,196.830185 
	C670.835815,195.243271 669.830383,193.770798 668.117249,190.905960 
	C666.620361,194.076019 664.782288,196.600647 664.327820,199.353653 
	C663.948547,201.651428 665.598145,204.212326 665.624878,206.664581 
	C665.673706,211.146271 667.528442,212.794037 672.406921,211.182800 
M411.412292,199.373383 
	C411.944946,201.449768 412.516541,203.517151 412.998108,205.605316 
	C413.396698,207.333527 413.123138,209.756760 414.179871,210.648575 
	C415.347839,211.634262 417.691254,211.227097 419.522705,211.426575 
	C419.467712,210.555389 419.748291,209.419724 419.303680,208.855576 
	C416.222290,204.945465 416.200348,203.273148 419.178131,199.550919 
	C419.628754,198.987625 419.716431,197.642532 419.354187,197.016495 
	C418.427063,195.414154 417.151672,194.013306 415.342285,191.654022 
	C414.062225,193.788757 412.621185,196.191910 411.412292,199.373383 
M348.156097,197.012787 
	C346.495117,197.012787 344.834167,197.012787 343.007874,197.012787 
	C343.007874,201.163361 343.066986,204.988129 342.990021,208.810150 
	C342.923035,212.138931 344.412140,212.363419 347.111481,211.049347 
	C347.111481,208.618652 346.769196,206.220413 347.233429,203.990067 
	C347.536163,202.535583 348.937866,201.263458 349.984894,200.026077 
	C350.714447,199.163864 351.677490,198.499207 352.536224,197.746292 
	C351.302277,197.382172 350.068298,197.018051 348.156097,197.012787 
M644.957520,194.723175 
	C645.128479,192.115112 644.900024,189.937698 641.228882,191.338211 
	C641.228882,198.071259 641.228882,204.778671 641.228882,211.486084 
	C641.706421,211.726227 642.184021,211.966370 642.661560,212.206528 
	C643.426575,211.003998 644.771667,209.838516 644.851318,208.592209 
	C645.125427,204.301422 644.957275,199.982407 644.957520,194.723175 
M572.882874,194.679749 
	C572.883362,199.338165 572.969116,203.998688 572.854675,208.654282 
	C572.776428,211.839127 574.181458,212.245163 576.782593,211.316986 
	C576.782593,204.490662 576.782593,197.897125 576.782593,191.303589 
	C576.377686,191.020477 575.972778,190.737350 575.567871,190.454239 
	C574.673279,191.542557 573.778687,192.630890 572.882874,194.679749 
M674.529846,206.304504 
	C674.529968,206.962082 674.320618,207.717514 674.572693,208.257385 
	C675.149658,209.492844 675.952087,210.622955 676.662964,211.795853 
	C677.352905,210.587479 678.499512,209.429306 678.639038,208.160431 
	C678.942505,205.399124 678.914429,202.563568 678.643494,199.795395 
	C678.534180,198.678772 677.433899,197.659149 676.784607,196.595428 
	C676.034119,197.558594 674.830200,198.437668 674.632568,199.503357 
	C674.278870,201.410645 674.531250,203.430344 674.529846,206.304504 
M469.493561,206.306366 
	C469.493744,206.963852 469.283356,207.720444 469.536682,208.258820 
	C470.118073,209.494446 470.925507,210.623703 471.641144,211.796127 
	C472.330048,210.586761 473.474915,209.427460 473.614319,208.157776 
	C473.917480,205.396759 473.889740,202.561584 473.618561,199.793839 
	C473.509247,198.678131 472.407745,197.659607 471.757812,196.596863 
	C471.003906,197.560089 469.794769,198.438477 469.595978,199.505142 
	C469.240723,201.411530 469.494720,203.431442 469.493561,206.306366 
M223.472610,143.742737 
	C223.568924,143.649979 223.731369,143.564682 223.742920,143.462357 
	C223.754440,143.360336 223.614685,143.241211 223.541260,143.129608 
	C223.444809,143.222244 223.348373,143.314896 223.472610,143.742737 
z"/>
<path fill="#0B5882" opacity="1.000000" stroke="none" 
	d="
M486.530029,119.424316 
	C489.498749,124.239075 492.255096,128.757858 495.814453,134.593170 
	C495.814453,121.341637 495.814453,109.532806 495.814453,97.362915 
	C500.418457,97.362915 504.458588,97.362915 508.857422,97.362915 
	C508.857422,117.855530 508.857422,138.239075 508.857422,159.502090 
	C504.799469,159.502090 500.704895,159.757401 496.678009,159.347702 
	C495.457855,159.223572 494.165649,157.421982 493.347137,156.113159 
	C486.770813,145.597183 480.299988,135.015228 472.826172,124.633141 
	C472.826172,134.490845 472.906525,144.349823 472.750732,154.205078 
	C472.723267,155.940689 471.828979,159.089783 471.128754,159.149048 
	C467.412811,159.463547 463.621826,159.083252 459.888153,158.664490 
	C459.556549,158.627289 459.268005,156.586624 459.265686,155.475815 
	C459.227905,137.335037 459.280304,119.193970 459.198364,101.053482 
	C459.185272,98.158768 460.003204,97.152229 463.119019,96.863426 
	C469.860535,96.238548 474.717316,97.666901 477.644257,104.590118 
	C479.820801,109.738304 483.376373,114.303482 486.530029,119.424316 
z"/>
<path fill="#0B5882" opacity="1.000000" stroke="none" 
	d="
M417.825684,133.101318 
	C417.825531,140.913406 417.711365,148.244431 417.879456,155.568985 
	C417.950470,158.664154 416.778687,159.637009 413.793945,159.591888 
	C402.170654,159.416260 404.341339,160.522583 404.269135,150.594635 
	C404.154114,134.772766 404.238434,118.949455 404.238464,103.126755 
	C404.238464,101.332130 404.238464,99.537506 404.238464,97.006546 
	C408.717163,97.006546 412.655151,96.797791 416.544495,97.140785 
	C417.633240,97.236794 418.830200,98.801979 419.550171,99.959129 
	C425.612457,109.702309 431.569641,119.510857 437.574982,129.289581 
	C438.403595,130.638855 439.322876,131.932449 440.855164,134.236816 
	C440.855164,121.339157 440.855164,109.612534 440.855164,97.440598 
	C445.250610,97.440598 449.294952,97.440598 453.781555,97.440598 
	C453.781555,117.850739 453.781555,138.376404 453.781555,159.541656 
	C449.902191,159.541656 446.074371,159.835083 442.333923,159.391678 
	C440.914246,159.223373 439.311157,157.601151 438.446442,156.228989 
	C431.814209,145.705139 425.343323,135.079605 417.825806,124.564384 
	C417.825806,127.248810 417.825806,129.933228 417.825684,133.101318 
z"/>
<path fill="#055476" opacity="1.000000" stroke="none" 
	d="
M185.792755,187.657150 
	C182.006165,184.256348 178.784622,180.412109 174.670502,178.340881 
	C164.331924,173.135986 159.707977,164.637299 163.015915,154.988281 
	C166.238754,145.587555 176.437988,140.802734 187.183624,143.650360 
	C202.659195,147.751450 214.015396,165.837494 211.664093,182.638290 
	C209.052979,201.295273 194.482147,214.629852 175.755341,215.487686 
	C174.697525,215.536148 173.635544,215.493973 171.348526,215.493973 
	C185.675415,208.539185 190.023911,199.792755 185.792755,187.657150 
z"/>
<path fill="#3DB64D" opacity="1.000000" stroke="none" 
	d="
M62.925663,102.714935 
	C66.636261,102.965652 69.946854,103.241875 73.251053,103.179405 
	C82.893005,102.997108 90.145111,108.403145 92.214401,117.481026 
	C94.129379,125.881966 89.638428,134.358063 81.226250,138.174545 
	C73.814133,141.537323 66.321426,141.120972 58.922409,138.289169 
	C46.173641,133.409882 37.911026,124.319214 35.284103,110.892723 
	C32.308002,95.681519 37.196831,83.048935 49.409035,73.398956 
	C50.853466,72.257584 52.604397,71.504097 54.708744,70.932579 
	C50.660320,78.195107 47.905125,85.699638 52.254467,93.124382 
	C54.504379,96.965195 59.026951,99.474709 62.925663,102.714935 
z"/>
<path fill="#8DC844" opacity="1.000000" stroke="none" 
	d="
M107.696014,93.804901 
	C96.749596,100.702164 85.011848,97.787636 77.774628,86.672752 
	C69.034012,73.248970 70.738579,53.434517 81.596062,42.251129 
	C94.806465,28.644190 113.299583,25.836075 129.199951,35.687981 
	C127.083046,35.687981 125.771034,35.714470 124.460381,35.683666 
	C116.214218,35.489853 109.873100,38.910450 106.021317,46.163807 
	C102.128700,53.494072 103.956345,60.280533 108.905594,66.543152 
	C116.730087,76.444023 116.408508,84.987450 107.696014,93.804901 
z"/>
<path fill="#3DB64D" opacity="1.000000" stroke="none" 
	d="
M103.826263,173.297592 
	C98.884583,191.913559 70.956474,202.510056 51.169594,188.338364 
	C36.728416,177.995361 31.435118,162.384705 36.157539,146.704895 
	C36.483929,145.621185 36.943729,144.577667 37.667957,142.643600 
	C38.122536,145.514191 38.310078,147.436890 38.742947,149.302673 
	C40.501831,156.883881 44.976036,162.111649 52.517338,164.321777 
	C59.869228,166.476410 66.020554,163.996094 70.943787,158.518753 
	C75.756630,153.164200 81.174858,149.349792 88.740753,150.469406 
	C99.592567,152.075256 105.228279,160.533920 103.826263,173.297592 
z"/>
<path fill="#5DB2E2" opacity="1.000000" stroke="none" 
	d="
M225.803406,141.091095 
	C225.047073,140.664871 224.201859,139.981873 224.064728,139.178070 
	C223.430008,135.457916 223.700928,131.423981 222.353043,128.014099 
	C218.000427,117.002831 206.134918,113.586578 195.390717,120.053787 
	C188.213562,124.373917 181.118546,125.430702 173.944427,120.437752 
	C168.316727,116.521072 165.480133,110.903862 166.260269,104.121910 
	C167.256256,95.463554 172.455261,89.513092 180.288345,86.124695 
	C194.814423,79.841072 212.182755,83.656357 222.338440,95.065254 
	C233.898590,108.051933 235.285141,125.172440 225.803406,141.091095 
z"/>
<path fill="#5DB2E2" opacity="1.000000" stroke="none" 
	d="
M147.745972,40.627853 
	C162.219543,33.318939 175.741821,34.719669 188.504517,43.747776 
	C196.762604,49.589397 202.561249,60.510616 201.496597,67.454597 
	C199.208725,65.326851 197.173096,62.888920 194.645752,61.176815 
	C183.239578,53.449833 169.665924,58.709976 165.975464,72.129013 
	C163.102707,82.574768 157.793213,87.752609 149.489594,88.206146 
	C137.212784,88.876694 128.261581,79.318840 129.573318,66.979973 
	C130.841263,55.053108 137.593750,46.888889 147.745972,40.627853 
z"/>
<path fill="#045279" opacity="1.000000" stroke="none" 
	d="
M86.175797,210.807877 
	C91.446823,214.701736 97.169823,217.039291 103.865242,215.699646 
	C112.277382,214.016510 117.826950,207.388977 118.070251,198.789169 
	C118.121979,196.960938 117.981995,195.128021 117.963394,193.296936 
	C117.871346,184.236557 123.128304,177.114014 131.335068,175.162140 
	C139.873352,173.131393 148.386688,177.509918 152.193466,185.889908 
	C157.162521,196.828369 153.468063,211.749359 143.443253,221.469818 
	C126.749733,237.656479 97.419044,234.718521 85.725021,211.736755 
	C85.929916,211.079895 86.052856,210.943878 86.175797,210.807877 
z"/>
<path fill="#095781" opacity="1.000000" stroke="none" 
	d="
M748.265259,97.006493 
	C752.214905,96.994980 755.693420,96.994980 759.830200,96.994980 
	C759.830200,104.866814 759.830200,112.418831 759.830200,120.180016 
	C767.431702,120.180016 774.373535,120.180016 781.799377,120.180016 
	C781.799377,112.700897 781.799377,105.265945 781.799377,97.417770 
	C786.633911,97.417770 791.000244,97.417770 795.717285,97.417770 
	C795.717285,117.906090 795.717285,138.314713 795.717285,159.134491 
	C791.312622,159.134491 786.923279,159.134491 782.041626,159.134491 
	C782.041626,150.360657 782.041626,141.773911 782.041626,132.871490 
	C774.645691,132.871490 767.725891,132.871490 760.180908,132.871490 
	C760.180908,141.438644 760.180908,150.153427 760.180908,159.196518 
	C755.153992,159.196518 750.747864,159.196518 745.899658,159.196518 
	C745.899658,150.221985 745.899719,141.582626 745.899658,132.943253 
	C745.899658,122.445953 745.825073,111.947533 745.983643,101.452629 
	C746.006104,99.965645 747.162598,98.495789 748.265259,97.006493 
z"/>
<path fill="#0E5A84" opacity="1.000000" stroke="none" 
	d="
M655.235229,133.369690 
	C654.826782,137.955231 654.826782,142.268433 654.826782,147.435822 
	C662.246277,147.435822 669.658325,147.336075 677.064636,147.497955 
	C679.223999,147.545151 682.939819,147.889496 683.221252,148.874817 
	C684.050720,151.778702 683.686646,155.089966 683.360413,158.196701 
	C683.301147,158.761169 681.216187,159.494263 680.061218,159.503510 
	C668.068298,159.599548 656.073547,159.490067 644.081055,159.612778 
	C640.899719,159.645309 640.169556,158.275208 640.183533,155.390549 
	C640.271790,137.233810 640.251953,119.076378 640.202087,100.919350 
	C640.195068,98.370407 640.695312,96.900543 643.761353,96.931236 
	C656.242004,97.056198 668.724731,96.983246 681.683838,96.983246 
	C681.683838,100.917282 681.683838,104.608322 681.683838,108.900925 
	C672.817810,108.900925 663.917358,108.900925 654.825989,108.900925 
	C654.825989,113.105949 654.825989,116.723404 654.825989,120.982719 
	C661.587769,120.982719 668.367920,121.151764 675.133606,120.913231 
	C678.650818,120.789223 680.256653,121.505341 680.093262,125.506561 
	C679.735840,134.260895 680.810608,132.921127 672.632019,133.010147 
	C666.969543,133.071793 661.306458,133.070496 655.235229,133.369690 
z"/>
<path fill="#0A5782" opacity="1.000000" stroke="none" 
	d="
M550.858459,147.453278 
	C553.791443,147.453400 556.243286,147.453400 558.888611,147.453400 
	C558.888611,151.633102 558.888611,155.089355 558.888611,159.047089 
	C544.641846,159.047089 530.481628,159.047089 516.190308,159.047089 
	C516.190308,138.411133 516.190308,117.999680 516.190308,97.003098 
	C529.413452,97.003098 542.351868,96.958015 555.288147,97.106560 
	C556.132385,97.116249 557.588074,98.545410 557.679688,99.426575 
	C557.983948,102.352386 557.794678,105.329536 557.794678,108.803589 
	C548.348633,108.803589 539.457153,108.803589 530.299316,108.803589 
	C530.299316,113.057213 530.299316,116.782738 530.299316,120.930634 
	C538.507507,120.930634 546.607300,120.930634 555.098022,120.930634 
	C555.098022,125.065163 555.098022,128.784119 555.098022,132.923218 
	C546.756592,132.923218 538.660583,132.923218 530.299072,132.923218 
	C530.299072,137.898163 530.299072,142.459686 530.299072,147.453140 
	C537.128113,147.453140 543.752686,147.453140 550.858459,147.453278 
z"/>
<path fill="#095781" opacity="1.000000" stroke="none" 
	d="
M740.782227,114.790466 
	C736.349182,116.349716 732.089905,117.558807 727.595581,118.834641 
	C725.723328,113.387283 723.018494,109.471024 717.227295,108.787552 
	C709.802490,107.911278 704.618896,110.879974 701.972900,117.913780 
	C699.603943,124.211296 699.653320,130.649048 701.448792,137.047455 
	C703.310730,143.682724 707.772400,147.568100 713.576660,147.948639 
	C719.795227,148.356354 724.394104,145.137329 727.205627,138.417511 
	C727.519653,137.667084 727.867310,136.930786 728.360413,135.827759 
	C732.861755,136.924088 737.287659,138.002045 742.002686,139.150406 
	C740.094971,147.927078 735.666687,154.011292 728.213379,157.681046 
	C709.455383,166.916931 688.349487,155.631989 686.209045,134.892029 
	C685.571716,128.716202 685.864441,122.132828 687.313049,116.117195 
	C690.467224,103.019249 701.319580,95.812050 715.801758,95.995094 
	C729.019775,96.162155 737.741943,102.557961 740.782227,114.790466 
z"/>
<path fill="#0B5882" opacity="1.000000" stroke="none" 
	d="
M352.264893,159.536926 
	C348.045776,159.546204 344.252747,159.546204 339.568787,159.546204 
	C340.300568,157.157013 340.699615,155.482025 341.317993,153.892319 
	C348.174866,136.264328 355.177765,118.691750 361.846191,100.993027 
	C363.074615,97.732597 364.613892,96.833771 367.928192,96.906288 
	C376.984131,97.104393 376.990906,96.943916 380.428925,105.494987 
	C386.866028,121.505539 393.306671,137.514694 399.721741,153.534073 
	C400.374878,155.165070 400.863892,156.861786 401.766937,159.523956 
	C396.703766,159.523956 392.434784,159.802444 388.245819,159.346283 
	C387.061584,159.217300 385.694061,157.102615 385.119171,155.623306 
	C380.153900,142.846100 375.350708,130.005890 370.178680,116.337364 
	C366.316101,126.600899 362.689117,136.147339 359.137360,145.721680 
	C357.754944,149.448273 356.647308,153.279465 355.183655,156.971497 
	C354.778900,157.992477 353.544647,158.684616 352.264893,159.536926 
z"/>
<path fill="#105B85" opacity="1.000000" stroke="none" 
	d="
M294.078339,120.184174 
	C292.133240,130.105499 287.210754,134.874481 277.689423,135.973495 
	C272.924255,136.523529 268.102173,136.580444 262.622620,136.900253 
	C262.622620,142.879730 262.435852,148.824371 262.691315,154.749939 
	C262.849457,158.418549 261.738892,159.757034 257.916718,159.645782 
	C246.360275,159.309433 248.373627,160.829987 248.242798,150.236099 
	C248.140030,141.913651 248.222168,133.588943 248.222168,124.364746 
	C255.855637,124.364746 263.299225,124.693237 270.687286,124.196030 
	C273.472321,124.008591 277.164337,122.637978 278.640350,120.539108 
	C282.363403,115.245056 278.600250,108.887978 271.962891,108.638474 
	C265.805145,108.406990 259.632263,108.592392 253.466171,108.562225 
	C251.845337,108.554306 250.225067,108.427780 248.784576,108.363724 
	C248.503281,107.634743 248.301620,107.337204 248.281708,107.027954 
	C247.634460,96.978951 247.637070,96.976379 257.643005,96.979996 
	C262.809235,96.981865 267.978516,96.882858 273.141144,97.022545 
	C288.237762,97.431000 294.849548,104.652267 294.078339,120.184174 
z"/>
<path fill="#155E87" opacity="1.000000" stroke="none" 
	d="
M334.532135,147.411255 
	C341.864014,147.994263 337.787048,153.229172 338.569000,156.279877 
	C339.358521,159.359924 336.867218,159.613007 334.488800,159.605148 
	C323.491333,159.568771 312.492584,159.504028 301.496735,159.642609 
	C298.272583,159.683243 297.126953,158.640640 297.150299,155.332809 
	C297.277435,137.337631 297.294220,119.340385 297.142609,101.345589 
	C297.112244,97.740379 298.394989,96.639877 301.806610,96.919464 
	C304.768982,97.162247 307.767029,96.969978 311.311096,96.969978 
	C311.311096,113.726151 311.311096,130.137695 311.311096,147.410385 
	C319.258972,147.410385 326.670105,147.410385 334.532135,147.411255 
z"/>
<path fill="#0A5782" opacity="1.000000" stroke="none" 
	d="
M581.548706,157.264038 
	C581.513062,141.037613 581.513062,125.248871 581.513062,109.009041 
	C576.756958,109.009041 572.444580,108.815842 568.158813,109.070564 
	C564.769226,109.272026 563.648926,107.938194 563.991516,104.736839 
	C564.167847,103.088959 563.701233,101.315826 564.136292,99.771423 
	C564.439331,98.695396 565.765015,97.135513 566.660889,97.117241 
	C576.121826,96.924187 585.588257,96.997696 595.848694,96.997696 
	C595.848694,104.844772 595.848633,112.305321 595.848694,119.765869 
	C595.848755,131.598953 595.752319,143.433350 595.911682,155.264297 
	C595.955688,158.531235 594.899536,160.036804 591.634705,159.526901 
	C588.271606,159.001663 584.933472,158.316757 581.548706,157.264038 
z"/>
<path fill="#095781" opacity="1.000000" stroke="none" 
	d="
M632.844543,123.999985 
	C632.844727,134.827332 632.760742,145.155792 632.897766,155.481308 
	C632.937439,158.476990 632.003235,159.652313 628.914978,159.585831 
	C616.034851,159.308624 618.692139,161.084518 618.544128,149.491867 
	C618.374268,136.181763 618.504761,122.867813 618.504761,109.007095 
	C614.604797,109.007095 610.981079,109.007408 607.357361,109.007034 
	C601.005066,109.006386 601.005127,109.006203 601.024414,102.445847 
	C601.025391,102.112709 601.066711,101.773285 601.018250,101.447372 
	C600.498596,97.955215 601.935852,96.826775 605.469421,96.916435 
	C614.437134,97.143990 623.414368,96.992043 632.844543,96.992043 
	C632.844543,105.989876 632.844543,114.744926 632.844543,123.999985 
z"/>
<path fill="#155E87" opacity="1.000000" stroke="none" 
	d="
M429.192322,205.616364 
	C427.742889,194.574615 432.742157,188.534180 441.356842,191.051941 
	C444.323853,191.919098 446.692719,194.832581 450.090179,197.376938 
	C452.957397,196.625977 452.400757,186.726425 460.414978,192.080582 
	C458.664429,193.161942 457.298645,194.005630 454.641846,195.646790 
	C457.279419,196.182953 458.657257,196.463043 459.997559,196.735504 
	C462.531830,189.673553 465.911499,188.584915 470.459595,193.263458 
	C468.605316,193.605042 466.829437,193.932190 464.287872,194.400391 
	C466.451294,196.711884 470.510071,198.304657 465.311523,200.989975 
	C464.187836,201.570419 465.293610,206.035217 464.896576,208.638229 
	C464.701050,209.920029 463.418884,211.036041 462.628418,212.227081 
	C462.135223,211.981155 461.642059,211.735229 461.148895,211.489319 
	C461.148895,207.883377 461.148895,204.277451 461.148895,200.311890 
	C459.435547,200.116150 458.047180,199.957535 456.760742,199.810577 
	C454.420685,203.493286 458.025696,209.141815 453.322723,212.787460 
	C450.290588,207.686478 454.344147,202.523529 449.270935,198.954025 
	C448.651123,205.198807 447.598663,210.745132 440.727081,211.862076 
	C435.634857,212.689789 431.534424,211.006851 429.192322,205.616364 
M436.942322,208.007446 
	C440.312195,208.724380 443.420837,207.849670 443.730377,204.270096 
	C443.985901,201.315277 443.080383,197.913254 441.594482,195.342148 
	C441.072083,194.438278 435.674805,194.751755 435.307251,195.652969 
	C434.117920,198.568970 434.062500,202.010757 433.960846,205.262177 
	C433.935669,206.066559 435.388397,206.917130 436.942322,208.007446 
z"/>
<path fill="#1F668C" opacity="1.000000" stroke="none" 
	d="
M362.559082,211.663010 
	C361.751831,204.871292 361.375488,198.197891 360.968933,190.989166 
	C365.483795,190.989166 369.254700,190.721375 372.970032,191.061172 
	C377.042511,191.433624 378.648438,194.786957 377.228058,198.628464 
	C376.868347,199.601273 376.981934,201.137985 377.533203,201.993347 
	C380.352600,206.368149 378.635010,210.831223 373.401306,211.534302 
	C369.981506,211.993683 366.463837,211.724289 362.559082,211.663010 
M367.550934,202.781906 
	C366.875549,204.356323 366.200195,205.930756 365.302094,208.024368 
	C368.105988,208.024368 369.689728,208.334747 371.062958,207.930389 
	C372.253387,207.579834 373.205231,206.419189 374.262543,205.616608 
	C373.331696,204.694580 372.528198,203.545135 371.426514,202.928131 
	C370.654419,202.495697 369.475403,202.789810 367.550934,202.781906 
M370.093384,199.056427 
	C371.248657,198.085953 372.403931,197.115463 373.559235,196.144989 
	C371.178223,195.374298 368.797241,194.603622 366.458771,193.846710 
	C365.198181,197.844833 365.818634,199.531906 370.093384,199.056427 
z"/>
<path fill="#135E86" opacity="1.000000" stroke="none" 
	d="
M619.415955,193.864960 
	C619.537109,195.067230 619.454346,195.939728 619.371643,196.812210 
	C618.087646,196.431427 616.469238,196.414429 615.583496,195.600342 
	C613.115051,193.331528 611.083130,193.541122 608.125000,196.393967 
	C610.406250,197.389587 612.030579,198.374039 613.790710,198.809052 
	C617.404724,199.702271 621.146790,201.056534 620.652954,205.251328 
	C620.378662,207.580536 617.854736,210.672424 615.625366,211.513306 
	C611.880493,212.925842 607.648682,212.479935 605.080261,208.364670 
	C604.573486,207.552689 604.337769,206.571579 604.586853,204.503571 
	C606.031372,205.168182 607.453552,205.888062 608.926331,206.482391 
	C610.509216,207.121155 612.130493,208.023575 613.752869,208.062286 
	C614.637451,208.083405 615.559082,206.552887 616.463989,205.722153 
	C615.717590,204.924774 615.113770,203.805023 614.194519,203.398361 
	C612.389465,202.599869 609.986694,202.689514 608.609070,201.506149 
	C606.935913,200.068939 604.962769,197.575470 605.161560,195.762985 
	C605.357239,193.979385 607.840698,191.692993 609.797607,191.024521 
	C613.136536,189.883942 616.752991,190.174011 619.415955,193.864960 
z"/>
<path fill="#135E86" opacity="1.000000" stroke="none" 
	d="
M557.093994,210.931061 
	C553.573059,212.878113 550.183411,212.278122 549.092773,209.273117 
	C547.976562,206.197327 547.718567,201.954254 549.042236,199.144119 
	C549.952637,197.211426 554.201355,196.851227 557.229248,196.389526 
	C559.362061,195.437683 555.582886,189.827621 561.844055,191.213943 
	C561.844055,197.971466 561.844055,204.683014 561.844055,212.056473 
	C560.121582,211.551208 558.798706,211.163162 557.093994,210.931061 
M556.084595,199.745560 
	C551.735901,198.871429 551.250366,201.890213 551.721863,204.754074 
	C551.967896,206.248154 553.894531,207.465515 555.062012,208.807892 
	C556.038879,206.791672 557.186218,204.832611 557.892700,202.725739 
	C558.116089,202.059494 557.160828,200.998047 556.084595,199.745560 
z"/>
<path fill="#1A638A" opacity="1.000000" stroke="none" 
	d="
M527.926453,201.500122 
	C527.938782,205.060501 527.938782,208.176270 527.938782,211.240570 
	C525.268555,211.500214 522.654236,212.301971 520.305908,211.816696 
	C518.275574,211.397125 516.547424,209.515442 514.685669,208.280075 
	C515.888794,206.566422 516.786926,204.437927 518.385803,203.262695 
	C519.815918,202.211563 522.018616,202.211578 523.880371,201.747803 
	C523.800903,201.141098 523.721436,200.534393 523.641968,199.927689 
	C520.924500,200.191467 518.207092,200.455246 515.148132,200.752182 
	C516.238342,196.579987 519.139160,196.097916 522.198608,196.193802 
	C525.279541,196.290329 527.737915,197.406281 527.926453,201.500122 
z"/>
<path fill="#135E86" opacity="1.000000" stroke="none" 
	d="
M394.705566,203.929871 
	C391.526306,204.817581 388.377716,205.321854 385.045715,205.855484 
	C386.639160,213.036804 391.598846,204.303329 394.051270,208.304153 
	C392.356262,211.863327 389.221436,212.659409 385.581177,211.688812 
	C381.461090,210.590302 380.512085,207.250397 380.706635,203.462463 
	C380.911560,199.471008 382.903015,196.657028 386.965363,196.301636 
	C390.618988,195.981995 393.421967,197.629471 394.221436,201.619827 
	C394.351776,202.270294 394.562622,202.904633 394.705566,203.929871 
z"/>
<path fill="#186189" opacity="1.000000" stroke="none" 
	d="
M505.242432,202.433121 
	C504.494202,203.618042 503.756500,205.004440 502.818939,205.156219 
	C500.635040,205.509766 498.354980,205.268936 496.113922,205.268936 
	C495.992371,205.970871 495.870850,206.672791 495.749298,207.374725 
	C497.216644,207.732880 498.700592,208.426453 500.146606,208.354675 
	C501.587555,208.283173 502.990417,207.443985 504.613983,207.510727 
	C503.144348,211.906036 499.957214,212.704025 496.318817,211.687347 
	C492.266327,210.554947 491.182373,207.175003 491.434113,203.425613 
	C491.694580,199.546021 493.653870,196.592606 497.762451,196.281097 
	C501.686798,195.983566 504.478607,197.865021 505.242432,202.433121 
z"/>
<path fill="#105B85" opacity="1.000000" stroke="none" 
	d="
M316.066467,194.414963 
	C314.610535,195.592468 313.616913,196.765549 312.846924,197.674622 
	C315.849426,200.904434 321.130066,196.518372 322.170380,202.755020 
	C319.114441,202.755020 316.499664,202.755020 314.486328,202.755020 
	C311.151978,205.193527 316.336731,211.506927 309.234650,211.737595 
	C309.234650,204.733093 309.234650,198.137070 309.234650,191.228470 
	C313.687195,191.228470 318.098602,191.228470 322.520721,191.228470 
	C322.767517,194.403564 322.767517,194.403564 316.066467,194.414963 
z"/>
<path fill="#1A638A" opacity="1.000000" stroke="none" 
	d="
M339.903870,207.740189 
	C337.179260,211.923981 333.707275,213.026871 329.465485,211.227859 
	C325.901733,209.716446 324.797760,206.637375 325.196014,203.107864 
	C325.605408,199.479248 327.757477,197.100388 331.437500,196.341904 
	C337.704742,195.050201 341.870453,200.409958 339.903870,207.740189 
M329.254059,203.605316 
	C330.436310,205.312210 331.618561,207.019104 332.800812,208.725967 
	C333.961853,207.754456 336.001678,206.855469 336.089142,205.795410 
	C336.240845,203.956833 335.769531,201.211533 334.507935,200.296799 
	C332.168152,198.600372 330.246552,200.199066 329.254059,203.605316 
z"/>
<path fill="#1A638A" opacity="1.000000" stroke="none" 
	d="
M691.994629,211.338486 
	C687.492126,212.772339 684.020569,211.853775 681.976685,207.894897 
	C680.218262,204.488922 680.701477,201.039032 683.501648,198.342651 
	C686.147217,195.795120 689.346130,195.552689 692.661194,197.148331 
	C697.889587,199.664932 698.384033,208.671417 691.994629,211.338486 
M691.895691,201.377930 
	C689.521057,198.855682 687.105835,198.806854 685.815552,202.113052 
	C685.224670,203.627289 685.829529,205.932785 686.621887,207.513596 
	C686.972961,208.214020 690.124573,208.497406 690.306824,208.129456 
	C691.224976,206.274704 691.583313,204.142807 691.895691,201.377930 
z"/>
<path fill="#135E86" opacity="1.000000" stroke="none" 
	d="
M652.328674,211.944214 
	C648.676392,209.711975 646.584045,202.361740 648.312805,198.128845 
	C648.669678,197.255020 650.378113,196.933167 652.284180,195.921341 
	C652.284180,199.794785 652.009827,202.632904 652.406738,205.373825 
	C652.589233,206.633804 654.087952,207.703171 654.989868,208.858948 
	C655.968201,207.703674 657.453064,206.682602 657.803345,205.360886 
	C658.299194,203.489655 657.643677,201.322906 658.088440,199.424988 
	C658.370667,198.220459 659.757446,197.274750 660.648865,196.212982 
	C661.026855,196.517792 661.404846,196.822617 661.782837,197.127426 
	C661.782837,201.886734 661.782837,206.646027 661.782837,211.162323 
	C658.586853,211.455597 655.660461,211.724121 652.328674,211.944214 
z"/>
<path fill="#135E86" opacity="1.000000" stroke="none" 
	d="
M624.515747,209.300568 
	C622.006287,203.682190 622.627441,199.677063 626.235962,197.497421 
	C629.575806,195.480042 632.995361,195.656464 635.879822,198.300797 
	C638.797058,200.975082 639.090088,204.441223 637.398865,207.874329 
	C635.620667,211.483795 632.392273,212.595779 628.622437,211.735062 
	C627.250671,211.421875 626.055969,210.333710 624.515747,209.300568 
M629.080078,208.271088 
	C633.135315,209.516632 634.440857,207.064346 633.973755,203.883087 
	C633.747620,202.343033 631.609009,199.897476 630.367859,199.916534 
	C626.726929,199.972504 626.759460,203.042465 627.181152,205.789795 
	C627.295593,206.535278 627.977783,207.193619 629.080078,208.271088 
z"/>
<path fill="#186189" opacity="1.000000" stroke="none" 
	d="
M717.955505,206.949982 
	C720.304138,207.682465 722.263550,208.415283 724.222900,209.148102 
	C724.463867,208.498413 724.704773,207.848724 724.945679,207.199036 
	C723.241882,206.563828 721.317322,206.236420 719.891602,205.213608 
	C718.202271,204.001633 716.942871,202.190323 715.497131,200.638763 
	C717.244629,199.161758 718.843933,196.753326 720.777466,196.445663 
	C723.146057,196.068756 725.794495,197.450455 728.321899,198.071777 
	C728.184326,198.846451 728.046753,199.621124 727.909180,200.395782 
	C725.227661,200.088928 722.546143,199.782074 719.864624,199.475235 
	C719.767944,199.927216 719.671265,200.379196 719.574524,200.831177 
	C721.483459,201.486099 723.583984,201.837524 725.244568,202.885834 
	C726.744873,203.832962 728.888062,205.693771 728.727844,206.878403 
	C728.497742,208.579559 726.539551,211.218689 725.131775,211.343399 
	C722.257812,211.598022 719.003113,210.967529 716.394592,209.696976 
	C714.404541,208.727676 714.904968,206.958008 717.955505,206.949982 
z"/>
<path fill="#186189" opacity="1.000000" stroke="none" 
	d="
M401.307526,199.571045 
	C402.281921,200.637268 403.707581,201.299683 404.846283,202.291519 
	C406.662354,203.873260 409.646179,205.626740 409.707825,207.392380 
	C409.832428,210.961685 406.434052,212.339966 403.267059,211.899277 
	C400.688934,211.540558 398.270996,210.030243 395.782043,209.030594 
	C396.015808,208.363373 396.249573,207.696167 396.483337,207.028961 
	C399.489075,207.656631 402.494781,208.284302 405.500519,208.911987 
	C405.650940,208.312256 405.801392,207.712540 405.951813,207.112808 
	C404.191437,206.488449 402.185944,206.202240 400.735291,205.150635 
	C399.074615,203.946747 397.906738,202.063019 396.524750,200.474670 
	C398.152649,199.071411 399.671448,196.693695 401.434326,196.496384 
	C403.991821,196.210159 406.725586,197.498795 409.384155,198.115738 
	C409.213196,198.941071 409.042206,199.766388 408.871216,200.591705 
	C406.457489,200.166153 404.043762,199.740601 401.307526,199.571045 
z"/>
<path fill="#186189" opacity="1.000000" stroke="none" 
	d="
M701.052612,211.763214 
	C700.098755,209.720169 699.317749,207.708130 699.125061,205.641235 
	C698.865967,202.863159 699.060791,200.042740 699.060791,197.355179 
	C702.201050,196.940353 705.102478,196.060120 707.868774,196.373474 
	C709.595276,196.569031 712.180847,198.324936 712.515869,199.803299 
	C713.269104,203.126999 713.028687,206.725830 712.784424,210.183823 
	C712.735229,210.881348 710.880066,211.451294 708.904114,212.666290 
	C708.904114,209.002731 709.217773,206.503677 708.796509,204.135376 
	C708.503601,202.488251 707.200867,201.020737 706.349243,199.472977 
	C705.282959,201.077789 703.830505,202.558334 703.262390,204.323502 
	C702.728638,205.982010 703.280151,207.960968 703.044250,209.758286 
	C702.949036,210.484375 702.031067,211.102524 701.052612,211.763214 
z"/>
<path fill="#186189" opacity="1.000000" stroke="none" 
	d="
M533.045471,211.764160 
	C532.085999,210.062042 531.245300,208.390198 531.109192,206.662888 
	C530.863770,203.546661 531.039795,200.397232 531.039795,197.352097 
	C534.155090,196.939911 537.060059,196.059723 539.831421,196.370590 
	C541.557800,196.564240 544.144104,198.315872 544.481506,199.793671 
	C545.239624,203.113831 545.009216,206.711319 544.764404,210.166397 
	C544.714050,210.877289 542.845947,211.459396 540.882568,212.681931 
	C540.882568,209.003006 541.197815,206.505615 540.774658,204.140305 
	C540.480286,202.494949 539.171753,201.031021 538.316345,199.486023 
	C537.251831,201.084045 535.799622,202.557602 535.234985,204.316116 
	C534.702698,205.974106 535.255188,207.951538 535.022888,209.748703 
	C534.928772,210.476517 534.019958,211.099030 533.045471,211.764160 
z"/>
<path fill="#1A638A" opacity="1.000000" stroke="none" 
	d="
M479.462830,197.284119 
	C484.011414,195.411667 487.561523,195.823486 490.706360,201.610229 
	C488.309387,201.039642 486.955414,200.530365 485.573975,200.439758 
	C484.117676,200.344254 481.381042,200.542557 481.359741,200.794617 
	C481.164459,203.106583 481.438202,205.458206 481.575195,207.798264 
	C483.003906,207.628403 484.459320,207.574615 485.853333,207.253952 
	C487.087677,206.970032 488.253723,206.389191 489.893768,205.772537 
	C489.817474,210.152420 487.534424,211.547028 484.577942,211.928040 
	C481.185883,212.365189 478.298828,211.333282 476.905304,207.850220 
	C475.333771,203.922134 475.843750,200.392548 479.462830,197.284119 
z"/>
<path fill="#0B5882" opacity="1.000000" stroke="none" 
	d="
M587.081665,211.727798 
	C586.107117,210.375870 585.178955,209.044647 585.114868,207.673050 
	C584.914734,203.393814 585.041443,199.099335 585.041443,195.341187 
	C582.505432,194.350891 580.552856,193.588425 578.600281,192.825943 
	C578.778992,192.289276 578.957703,191.752609 579.136353,191.215942 
	C584.370056,191.215942 589.603699,191.215942 594.839111,191.219803 
	C594.872437,194.016159 594.872437,194.016159 589.212036,195.045853 
	C589.212036,199.206039 589.344788,203.484344 589.134460,207.745728 
	C589.067810,209.097031 588.086243,210.403198 587.081665,211.727798 
z"/>
<path fill="#186189" opacity="1.000000" stroke="none" 
	d="
M672.127319,211.451996 
	C667.528442,212.794037 665.673706,211.146271 665.624878,206.664581 
	C665.598145,204.212326 663.948547,201.651428 664.327820,199.353653 
	C664.782288,196.600647 666.620361,194.076019 668.117249,190.905960 
	C669.830383,193.770798 670.835815,195.243271 671.605225,196.830185 
	C671.970337,197.583221 672.311218,199.004807 671.938232,199.358002 
	C667.922058,203.161163 669.041077,206.488190 672.573608,209.780823 
	C672.776978,209.970398 672.474182,210.702942 672.127319,211.451996 
z"/>
<path fill="#135E86" opacity="1.000000" stroke="none" 
	d="
M411.296234,198.984222 
	C412.621185,196.191910 414.062225,193.788757 415.342285,191.654022 
	C417.151672,194.013306 418.427063,195.414154 419.354187,197.016495 
	C419.716431,197.642532 419.628754,198.987625 419.178131,199.550919 
	C416.200348,203.273148 416.222290,204.945465 419.303680,208.855576 
	C419.748291,209.419724 419.467712,210.555389 419.522705,211.426575 
	C417.691254,211.227097 415.347839,211.634262 414.179871,210.648575 
	C413.123138,209.756760 413.396698,207.333527 412.998108,205.605316 
	C412.516541,203.517151 411.944946,201.449768 411.296234,198.984222 
z"/>
<path fill="#155E87" opacity="1.000000" stroke="none" 
	d="
M348.495239,196.833344 
	C350.068298,197.018051 351.302277,197.382172 352.536224,197.746277 
	C351.677490,198.499207 350.714447,199.163864 349.984894,200.026077 
	C348.937866,201.263458 347.536163,202.535583 347.233429,203.990067 
	C346.769196,206.220413 347.111481,208.618652 347.111481,211.049347 
	C344.412140,212.363419 342.923035,212.138931 342.990021,208.810150 
	C343.066986,204.988129 343.007874,201.163361 343.007874,197.012787 
	C344.834167,197.012787 346.495117,197.012787 348.495239,196.833344 
z"/>
<path fill="#105B85" opacity="1.000000" stroke="none" 
	d="
M644.957581,195.198654 
	C644.957275,199.982407 645.125427,204.301422 644.851318,208.592209 
	C644.771667,209.838516 643.426575,211.003998 642.661560,212.206528 
	C642.184021,211.966370 641.706421,211.726227 641.228882,211.486084 
	C641.228882,204.778671 641.228882,198.071259 641.228882,191.338211 
	C644.900024,189.937698 645.128479,192.115112 644.957581,195.198654 
z"/>
<path fill="#095781" opacity="1.000000" stroke="none" 
	d="
M572.883545,194.199493 
	C573.778687,192.630890 574.673279,191.542557 575.567871,190.454239 
	C575.972778,190.737350 576.377686,191.020477 576.782593,191.303589 
	C576.782593,197.897125 576.782593,204.490662 576.782593,211.316986 
	C574.181458,212.245163 572.776428,211.839127 572.854675,208.654282 
	C572.969116,203.998688 572.883362,199.338165 572.883545,194.199493 
z"/>
<path fill="#1F668C" opacity="1.000000" stroke="none" 
	d="
M674.530029,205.853775 
	C674.531250,203.430344 674.278870,201.410645 674.632568,199.503357 
	C674.830200,198.437668 676.034119,197.558594 676.784546,196.595398 
	C677.433899,197.659149 678.534180,198.678772 678.643494,199.795395 
	C678.914429,202.563568 678.942505,205.399124 678.639038,208.160431 
	C678.499512,209.429306 677.352905,210.587479 676.662964,211.795837 
	C675.952087,210.622955 675.149658,209.492844 674.572693,208.257385 
	C674.320618,207.717514 674.529968,206.962082 674.530029,205.853775 
z"/>
<path fill="#1F668C" opacity="1.000000" stroke="none" 
	d="
M469.493774,205.855133 
	C469.494720,203.431442 469.240723,201.411530 469.595978,199.505142 
	C469.794769,198.438477 471.003906,197.560089 471.757812,196.596863 
	C472.407745,197.659607 473.509247,198.678131 473.618561,199.793839 
	C473.889740,202.561584 473.917480,205.396759 473.614319,208.157776 
	C473.474915,209.427460 472.330048,210.586761 471.641174,211.796143 
	C470.925507,210.623703 470.118073,209.494446 469.536682,208.258820 
	C469.283356,207.720444 469.493744,206.963852 469.493774,205.855133 
z"/>
<path fill="#045279" opacity="1.000000" stroke="none" 
	d="
M85.615982,211.413757 
	C84.988739,211.244461 84.552483,210.877289 83.582771,210.061142 
	C84.799789,210.173615 85.345146,210.224014 86.033150,210.541138 
	C86.052856,210.943878 85.929916,211.079895 85.615982,211.413757 
z"/>
<path fill="#5DB2E2" opacity="1.000000" stroke="none" 
	d="
M223.362274,143.575134 
	C223.348373,143.314896 223.444809,143.222244 223.541260,143.129608 
	C223.614685,143.241211 223.754440,143.360336 223.742920,143.462357 
	C223.731369,143.564682 223.568924,143.649979 223.362274,143.575134 
z"/>
<path fill="#F8FAFB" opacity="1.000000" stroke="none" 
	d="
M436.552032,207.876953 
	C435.388397,206.917130 433.935669,206.066559 433.960846,205.262177 
	C434.062500,202.010757 434.117920,198.568970 435.307251,195.652969 
	C435.674805,194.751755 441.072083,194.438278 441.594482,195.342148 
	C443.080383,197.913254 443.985901,201.315277 443.730377,204.270096 
	C443.420837,207.849670 440.312195,208.724380 436.552032,207.876953 
z"/>
<path fill="#F8FAFB" opacity="1.000000" stroke="none" 
	d="
M368.014526,202.770691 
	C369.475403,202.789810 370.654419,202.495697 371.426514,202.928131 
	C372.528198,203.545135 373.331696,204.694580 374.262543,205.616608 
	C373.205231,206.419189 372.253387,207.579834 371.062958,207.930389 
	C369.689728,208.334747 368.105988,208.024368 365.302094,208.024368 
	C366.200195,205.930756 366.875549,204.356323 368.014526,202.770691 
z"/>
<path fill="#EEF3F6" opacity="1.000000" stroke="none" 
	d="
M369.663361,199.058731 
	C365.818634,199.531906 365.198181,197.844833 366.458771,193.846710 
	C368.797241,194.603622 371.178223,195.374298 373.559235,196.144989 
	C372.403931,197.115463 371.248657,198.085953 369.663361,199.058731 
z"/>
<path fill="#EEF3F6" opacity="1.000000" stroke="none" 
	d="
M556.414795,199.931519 
	C557.160828,200.998047 558.116089,202.059494 557.892700,202.725739 
	C557.186218,204.832611 556.038879,206.791672 555.062012,208.807892 
	C553.894531,207.465515 551.967896,206.248154 551.721863,204.754074 
	C551.250366,201.890213 551.735901,198.871429 556.414795,199.931519 
z"/>
<path fill="#EEF3F6" opacity="1.000000" stroke="none" 
	d="
M329.335480,203.226959 
	C330.246552,200.199066 332.168152,198.600372 334.507935,200.296799 
	C335.769531,201.211533 336.240845,203.956833 336.089142,205.795410 
	C336.001678,206.855469 333.961853,207.754456 332.800842,208.725983 
	C331.618561,207.019104 330.436310,205.312210 329.335480,203.226959 
z"/>
<path fill="#EEF3F6" opacity="1.000000" stroke="none" 
	d="
M692.016907,201.743042 
	C691.583313,204.142807 691.224976,206.274704 690.306824,208.129456 
	C690.124573,208.497406 686.972961,208.214020 686.621887,207.513596 
	C685.829529,205.932785 685.224670,203.627289 685.815552,202.113052 
	C687.105835,198.806854 689.521057,198.855682 692.016907,201.743042 
z"/>
<path fill="#EEF3F6" opacity="1.000000" stroke="none" 
	d="
M628.739746,208.081512 
	C627.977783,207.193619 627.295593,206.535278 627.181152,205.789795 
	C626.759460,203.042465 626.726929,199.972504 630.367859,199.916534 
	C631.609009,199.897476 633.747620,202.343033 633.973755,203.883087 
	C634.440857,207.064346 633.135315,209.516632 628.739746,208.081512 
z"/>
</svg>
            <p className="c20 c24 c31 title" id="h.5x0d5h95i329">
            <span className="c6 c23 c32">Plannettech Chat Bot</span>
            </p>
            <p className="c20 c29 subtitle" id="h.sbziogryzzql">
                <span className="c6 c19">Reach Us via:</span>
            </p>
            <p className="c13">
                <span>email : info@plannettech.co.ke &nbsp;❙ &nbsp;</span>
                <span className="c7">
                    <a
                        className="c0"
                        href="https://plannettech.co.ke/"
                        target="_blank"
                    >
                        Plannettech Website
                    </a>
                </span>
            </p>
            <p className="c13">
                <span className="c7">
                    <a
                        className="c0"
                        href="https://www.linkedin.com/company/plannettech-investors-limited/"
                        target="_blank"
                    >
                        Linkedin
                    </a>
                </span>
                <span>&nbsp; ❙ &nbsp;</span>
                <span className="c7">
                    <a
                        className="c0"
                        href="https://www.facebook.com/plannettech"
                        target="_blank"
                    >
                        Facebook
                    </a>
                </span>
                <span>&nbsp; ❙ &nbsp;</span>
                <span className="c7">
                    <a
                        className="c0"
                        href=" https://www.instagram.com/plannettech/"
                        target="_blank"
                    >
                        Instagram
                    </a>
                </span>
                <span>&nbsp; ❙ &nbsp;</span>
                <span className="c7">
                    <a
                        className="c0"
                        href="https://x.com/plannettech"
                        target="_blank"
                    >
                        X (formerly twitter)
                    </a>
                </span>
            
            <h1 className="c8" id="h.8st02y5lress">
                <span className="c22 c6">ABOUT US</span>
            </h1>
                
                    <span className="c13">
					We are an ICT solutions provider dedicated to understanding our client's business needs. A Company comprised of experienced 
					proffesionals & associates with long and varied experince in the ICT Sector. This makes us an efficient, effective and reliable partner for your organization. </span>
                </p><ul className="c1 lst-kix_ep6cffw0fqwf-0 start">
                <li className="c2 li-bullet-0">
                    <span className="c3">
					A company focused on leveraging knowledge, technical innovation and teamwork to deliver solutions that improve customer performance.
                    </span>
                </li>
                <li className="c2 li-bullet-0">
                    <span className="c3">
					A company aiming at delivering "just-in-time" knowledge that meets the changing needs of customers not "just-in-case" information.
                </span> </li>
				<li className="c2 li-bullet-0">
                    <span className="c3">
                    A company whose service is designed for flexibilty and customization without compromising.
                    </span>
                </li></ul>
            <h1 className="c8 c20" id="h.4c5illgzjnmn">
                <span className="c22 c6">Why work with us?</span>
            </h1>
            <ul className="c1 lst-kix_95tlb3bidfwg-0 start">
                <li className="c2 li-bullet-0">
                    <span className="c3">We are driven by customer needs.</span>
                </li>
                <li className="c2 li-bullet-0">
                    <span className="c3">Proactive, we keep up with technological changes & seek alternative appropriate technologies that meet your objectives.</span>
                </li>
				<li className="c2 li-bullet-0">
                    <span className="c3">Premium quality. Our products & Services must meet your expectations.</span>
                </li>
				<li className="c2 li-bullet-0">
                    <span className="c3">We procure from approved sources locally & Internationally ensuring you get real value for money.</span>
                </li>
				<li className="c2 li-bullet-0">
                    <span className="c3">We are focused on going beyond being "Just another supplier" to entering into a long tern relationship with you.</span>
                </li>
				<li className="c2 li-bullet-0">
                    <span className="c3">We focus on quick turn-around time in the delivery of products and services as we are sensitive towards the impact of the same to your business.</span>
                </li>
            </ul>
            <h1 className="c8 c20" id="h.gjyj1pwfroz0">
                <span className="c22 c6">OUR PRODUCTS</span>
				</h1>
				<h2 className="c5" id="h.w8ukeiio9we4">
                <span className="c7">
                    <a
                        className="c0"
                        href="https://www.plannettech.co.ke/computing-2/"
                        target="_blank"
                    >
                        Computing Solutions
                    </a>
                </span>
                <span className="c23"> : Laptops, All in One PCs, Desktops, Tablets and Computer Accesories</span>
				
				<br></br><br></br><span className="c7">
                    <a
                        className="c0"
                        href="https://www.plannettech.co.ke/kra-fiscal-devices-2/"
                        target="_blank"
                    >
                        E-TIMS Solutions
                    </a>
                </span>
                <span className="c23"> : KRA fiscalised devices, ETRS and Bluetooth printers.  </span>
				<br></br><br></br><span className="c7">
                    <a
                        className="c0"
                        href="https://www.plannettech.co.ke/point-of-sale-2/"
                        target="_blank"
                    >
                        POS Hardware and Software
                    </a>
                </span>
                <span className="c23"> : POS Terminals, Cash Drawers, Receipt printers, Barcode Scanners, Label Printers and Thermal Printers.</span>
				<br></br><br></br><span className="c7">
                    <a
                        className="c0"
                        href="https://www.plannettech.co.ke/"
                        target="_blank"
                    >
                        Printing Solutions
                    </a>
                </span>
                <span className="c23"> : Printers, Scanners, Toners and Inks, Printing Papers and Rolls. </span>
				<br></br><br></br><span className="c7">
                    <a
                        className="c0"
                        href="https://www.plannettech.co.ke/"
                        target="_blank"
                    >
                        Stationery
                    </a>
                </span>
                <span className="c23"> : Photocopying Papers, Box Files, Pencils, Laminators, Whiteboards, Pens, Cash Boxes, PaperPunch, Staplers, Shredders, Key Cabinets, Envelopes, Numbering Machine, Binding Machines, Staple Pins, Sticky Tacks, Marker Pens, Dustbins, Lamination Pouches, File Dividers, Highlighters, White Out, Display Books, Laptop Bags, Glue, Scissors, Counter Books, Tapes and Tape Dispensers
Rulers. </span><br></br><br></br><span className="c7">
                    <a
                        className="c0"
                        href="https://www.plannettech.co.ke/"
                        target="_blank"
                    >
                        Security
                    </a>
                </span>
                <span className="c23"> : Security seals, CCTV Cameras, Safes and Biometric systems  </span>
            </h2>
		
            <h1 className="c8 c20" id="h.li0cxd3bk12v">
                <span className="c6 c22">ACCOLADES</span>
            </h1>
            
            <p className="c13">
                <span>WINNER : MSME Honours Award 2023 “Tech Transformer” Award.</span>
               
                    
					<br></br><br></br><br></br><br></br><span className="c7">
                        <a
                            className="c0"
                            href=""
                            target="_blank"
                        >
                            Plannettech Investors Limited ©️ 2024  
                        </a>
                    </span></p></div>)}