import React, { useEffect, useRef, useState } from "react";
import { ThreadMessage } from "openai/resources/beta/threads/messages/messages";
import Markdown from 'markdown-to-jsx';
import { Cv } from "./cv";
import axios from "axios";

//const BASE_URL = process.env.BASE_URL;
const BASE_URL = "ai.plannetech.co.ke";
if (!BASE_URL) {
    throw new Error("Missing BASE_URL environment variable.");
}

export function App() {

    const threadId = useRef<string | undefined>(undefined);
    const scrollContainer = useRef<HTMLDivElement>(null);
    const [runId, setRunId] = useState<string | undefined>(undefined);
    const [messages, setMessages] = useState<ThreadMessage[]>([]);
    const [message, setMessage] = useState<string>("");
    const [chatbotVisible, setChatbotVisible] = useState<boolean>(true);
    const [showInfo, setShowInfo] = useState<boolean>(true);

    //
    // Creates a new message thread, if there isn't one already.
    //
    async function createThread(): Promise<void> {
        
        if (threadId.current === undefined) {
            //
            // Try and reload from local storage.
            //
            const storedThreadId = localStorage.getItem("threadId");
            if (storedThreadId) {
                threadId.current = storedThreadId;
                await updateMessages();
            }
        }


        if (threadId.current !== undefined) {
            // Already have a thread.
            return; 
        }

        //
        // Requests a new thread from the backend.
        //
        const { data } = await axios.post(`${BASE_URL}/chat/new`);
        threadId.current = data.threadId;

        //
        // Save the thread id in local storage.
        //
        localStorage.setItem("threadId", threadId.current!);
    }

    //
    // Starts a new chat thread.
    //
    function onResetThread() {
        threadId.current = undefined;
        setMessages([]);
        setMessage("");
        setRunId(undefined);
        scrollContainer.current!.scrollTop = 0;
        localStorage.removeItem("threadId");

        createThread()
            .catch(err => {
                console.error(`Failed to create message thread.`);
                console.error(err);
            });
    }

    //
    // Adds a message to the chat.
    //
    async function sendMessage(text: string): Promise<void> {
        if (runId !== undefined) {
            // Already running.
            return;
        }

        //
        // Sends the message to the backend.
        //
        const { data } = await axios.post(`${BASE_URL}/chat/send`, {
            threadId: threadId.current,
            text,
        });

        setRunId(data.runId);
    }

    //
    // Sends the message the user has typed to the AI.
    //
    async function onSendMessage(): Promise<void> {
        const messageToSend = message.trim();
        setMessage(""); // Clear for the next message.
        await sendMessage(messageToSend);
    }

    //
    // Updates messages in the UI.
    //
    async function updateMessages(): Promise<void> {

        const { data } = await axios.post(`${BASE_URL}/chat/list`, {
            threadId: threadId.current,
            runId: runId,
        });

        const { messages, status } = data;
        
        messages.reverse(); // Reverse so the newest messages are at the bottom.
        setMessages(messages);

        if (runId) {
            if (status === "completed") {
                setTimeout(() => {
                    // The run has finished.
                    setRunId(undefined);
                }, 5000); // Give it some time to finish up.
            }
        }
    }

    //
    // Gets the role name for a message.
    //
    function getRoleName(role: string): string {
        if (role === "user") {
            return "You";
        } 
        else if (role === "assistant") {
            return "Plannetta AI";
        } 
        else {
            return role;
        }
    }

    //
    // Render a chunk of text as paragraphs.
    //
    function renderText(text: string, role: string) {
        return text.split("\n").map((line, index) => {
            return (
                <div key={index} className="leading-relaxed">
                    {index === 0 
                        && <span 
                            className="block font-bold text-gray-700"
                            >
                            {getRoleName(role)}
                        </span>
                    }
                    <Markdown>{line}</Markdown>
                </div>
            );
        });
    }

    useEffect(() => {
        createThread()
            .catch(err => {
                console.error(`Failed to create message thread.`);
                console.error(err);
            });
    }, []);

    useEffect(() => {

        if (runId === undefined) {
            return;
        }

        const timer = setInterval(() => {
            updateMessages();
        }, 1000);

        return () => {
            clearInterval(timer);
        };

    }, [runId]);

    useEffect(() => {
        //
        // Scroll to the end.
        //
        scrollContainer.current!.scrollTop = scrollContainer.current!.scrollHeight;

    }, [messages]);

    //
    // Toggles visibility of the chatbot.
    //
    function onToggleChatbot() {
        setChatbotVisible(!chatbotVisible);
    }

    //
    // Handles the enter key.
    //
    async function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>): Promise<void> {
        if (event.key === "Enter") {
            // Send message on enter.
            await onSendMessage();
        }
    }

    return (
        <div>
            {showInfo && <div
                style={{
                    boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)",
                    maxHeight: "80%",
                    maxWidth: "80%",
                    zIndex: 1000,
                }}
                className="flex flex-col fixed top-[50px] right-0 mr-4 bg-gray-50 p-6 rounded-lg border border-[#e5e7eb] w-[440px]"
                >
                <p className="text-lg">Hello! This is the plannettech ChatBOT.</p>
                <p className="pt-2"></p>
                <p>The chatbot below is suited to answer any queries you may have about IT, Technology, Office solutions and any other general questions. </p>

                <a 
                    className="mt-3 text-sm text-blue-600 font-medium underline"
                    href="https://www.plannettech.co.ke/"
                    target="_blank"
                    >
                    Check out our website for more info
                </a>

                <a 
                    className="mt-1 text-sm text-blue-600 font-medium underline"
                    href="https://www.facebook.com/plannettech" 
                    target="_blank"
                    >
                    Connect with us on social media.
                </a>

                <div 
                    className="absolute top-[10px] right-[10px] cursor-pointer"
                    onClick={() => setShowInfo(false)}
                    >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M18 6L6 18M6 6l12 12"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </div>}

            {chatbotVisible && <div
                style={{
                    boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)",
                    maxHeight: "80%",
                    maxWidth: "80%",
                }}
                className="flex flex-col fixed bottom-[calc(4rem+1.5rem)] right-0 mr-4 bg-gray-50 p-6 pt-2 rounded-lg border border-[#e5e7eb] w-[440px] h-[600px]"
                >

                {/* <!-- Heading --> */}
                <div className="flex flex-col space-y-1.5 pb-6">
                    <h2 className="font-semibold text-lg tracking-tight">Ask the All New Plannettech-ChatBOT a question.</h2>
                    <p className="text-xs text-[#6b7280] leading-3">Powered by Plannettech Investors Limited</p>
                    <p className="text-xs text-[#6b7280] leading-3">Answers are probalistic may contain errors. AI is still learning.</p>
                </div>

                {/* <!-- Chat Container --> */}
                <div 
                    ref={scrollContainer}
                    className="flex-grow overflow-y-auto mb-6 pr-4"
                    >
                    {/* <!-- Chat Message AI --> */}
                    <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1">
                        <span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                            <div className="rounded-full bg-gray-100 border p-1">
                            <svg version="1.1" id="Layer_1"  x="0px" y="0px"
	 width="100%" viewBox="0 0 226 241" enable-background="new 0 0 226 241" xmlSpace="preserve">
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M126.000000,242.000000 
	C84.000000,242.000000 42.500004,242.000000 1.000005,242.000000 
	C1.000004,161.666672 1.000004,81.333351 1.000002,1.000016 
	C76.333321,1.000011 151.666641,1.000011 226.999969,1.000005 
	C226.999985,81.333321 226.999985,161.666641 227.000000,241.999969 
	C193.500000,242.000000 160.000000,242.000000 126.000000,242.000000 
M146.491409,143.056503 
	C138.323257,154.376221 141.535645,166.300125 154.291306,172.002274 
	C155.201172,172.409012 156.140198,172.756256 157.028351,173.204987 
	C168.042816,178.769897 171.064713,191.895920 163.082077,201.349121 
	C160.357742,204.575348 156.362808,206.728622 153.062363,209.283905 
	C152.878571,209.158875 153.117432,209.461349 153.369751,209.472992 
	C170.644241,210.270645 185.703812,198.830826 190.815155,181.051498 
	C195.067581,166.259796 187.533859,148.258179 173.741028,140.253403 
	C164.583832,134.938950 154.820328,135.767014 146.491409,143.056503 
M38.501186,132.151047 
	C42.062019,132.940292 45.587982,134.142548 49.189774,134.446594 
	C64.052650,135.701263 75.640045,123.324120 71.973206,110.355202 
	C69.393890,101.232620 62.087646,96.772316 50.553524,97.389252 
	C42.969215,97.794922 36.673557,95.509720 32.814285,88.639473 
	C28.857149,81.595016 29.560928,74.589531 33.753510,67.828003 
	C34.452866,66.700119 35.174725,65.586189 36.117153,64.102104 
	C35.026093,64.228966 34.669163,64.187592 34.394112,64.318382 
	C33.643753,64.675201 32.899849,65.061218 32.202660,65.511887 
	C20.712320,72.939301 14.270797,83.512314 14.481328,97.171646 
	C14.732654,113.477730 22.633041,125.271133 38.501186,132.151047 
M107.348717,29.915096 
	C107.513779,29.893373 107.690804,29.901152 107.840118,29.840590 
	C107.959564,29.792143 108.045303,29.660603 108.146011,29.565945 
	C107.034698,28.737883 106.027061,27.686506 104.796532,27.115244 
	C86.294991,18.526131 63.376595,27.288383 55.264183,45.998680 
	C48.471409,61.665386 53.096642,80.493950 65.701111,88.485756 
	C77.999367,96.283394 93.376778,89.054070 94.385262,74.974480 
	C94.805969,69.101013 92.088242,64.501999 88.572563,60.045326 
	C79.758202,48.871700 84.550957,34.089882 98.181976,30.390364 
	C100.837654,29.669601 103.778313,29.998873 107.348717,29.915096 
M203.347198,90.225571 
	C200.030243,87.476624 196.978485,84.298111 193.348053,82.056961 
	C181.037033,74.457100 163.719482,75.486778 153.608231,84.449829 
	C147.806931,89.592331 144.693649,96.047440 146.803833,104.083366 
	C148.784149,111.624649 153.831894,116.629807 161.356308,117.240067 
	C165.944611,117.612198 171.090057,115.829300 175.403122,113.785736 
	C182.022186,110.649590 188.340805,109.335030 194.850327,113.239151 
	C201.622513,117.300804 204.399399,123.615616 204.104279,131.379150 
	C204.047104,132.883072 203.858170,134.382004 203.730026,135.883240 
	C204.158340,136.046799 204.586655,136.210342 205.014969,136.373886 
	C206.401031,133.886536 208.058090,131.509964 209.127777,128.893265 
	C214.727600,115.194664 212.476929,102.528160 203.347198,90.225571 
M18.043976,167.269455 
	C24.298574,179.336197 34.024311,186.575958 47.562485,188.555954 
	C61.842300,190.644409 77.803146,182.387375 82.824883,170.279587 
	C86.373901,161.722626 83.591866,152.002167 76.235527,147.256317 
	C68.613838,142.339249 59.816078,143.500961 53.073193,150.328094 
	C52.019936,151.394501 51.055279,152.550751 49.975422,153.588181 
	C41.469658,161.759796 28.808401,160.804214 22.280844,150.958694 
	C19.740482,147.127060 18.749895,142.267929 17.097822,137.991867 
	C13.591513,147.282349 13.719345,156.926163 18.043976,167.269455 
M179.798660,51.900578 
	C171.525894,33.695995 152.022400,27.356728 136.747818,31.028488 
	C122.545105,34.442585 107.982162,48.982498 109.753593,65.367027 
	C110.656395,73.717339 116.723122,80.956604 124.447334,82.077911 
	C134.041245,83.470642 141.832855,78.900528 144.879379,70.036224 
	C145.417816,68.469543 145.676178,66.808273 146.180450,65.227875 
	C148.294754,58.601524 152.338379,53.639378 159.338547,52.208916 
	C166.853607,50.673241 173.502686,52.778584 178.736237,58.633339 
	C179.622910,59.625263 180.473099,60.649815 181.340134,61.659294 
	C181.719437,61.457123 182.098724,61.254951 182.478027,61.052780 
	C181.669754,58.243374 180.861481,55.433968 179.798660,51.900578 
M132.070984,179.604385 
	C127.226631,171.176788 120.831047,167.693329 112.532547,168.962418 
	C105.717583,170.004639 99.987267,175.141235 98.497841,182.123367 
	C97.885262,184.995026 98.052658,188.067108 98.117912,191.042480 
	C98.272194,198.078156 95.867920,203.845901 89.668053,207.437805 
	C83.071938,211.259293 76.329567,210.895340 69.751549,207.125473 
	C68.396027,206.348602 67.075478,205.510681 64.931335,204.211731 
	C65.392761,205.647537 65.448433,206.085190 65.656738,206.430862 
	C74.457153,221.035721 87.093544,227.503571 104.232719,225.064026 
	C127.234642,221.790009 139.809082,196.121979 132.070984,179.604385 
z"/>
<path fill="#055476" opacity="1.000000" stroke="none" 
	d="
M146.742737,142.789185 
	C154.820328,135.767014 164.583832,134.938950 173.741028,140.253403 
	C187.533859,148.258179 195.067581,166.259796 190.815155,181.051498 
	C185.703812,198.830826 170.644241,210.270645 153.369751,209.472992 
	C153.117432,209.461349 152.878571,209.158875 153.062363,209.283905 
	C156.362808,206.728622 160.357742,204.575348 163.082077,201.349121 
	C171.064713,191.895920 168.042816,178.769897 157.028351,173.204987 
	C156.140198,172.756256 155.201172,172.409012 154.291306,172.002274 
	C141.535645,166.300125 138.323257,154.376221 146.742737,142.789185 
z"/>
<path fill="#3DB64D" opacity="1.000000" stroke="none" 
	d="
M38.134319,132.029907 
	C22.633041,125.271133 14.732654,113.477730 14.481328,97.171646 
	C14.270797,83.512314 20.712320,72.939301 32.202660,65.511887 
	C32.899849,65.061218 33.643753,64.675201 34.394112,64.318382 
	C34.669163,64.187592 35.026093,64.228966 36.117153,64.102104 
	C35.174725,65.586189 34.452866,66.700119 33.753510,67.828003 
	C29.560928,74.589531 28.857149,81.595016 32.814285,88.639473 
	C36.673557,95.509720 42.969215,97.794922 50.553524,97.389252 
	C62.087646,96.772316 69.393890,101.232620 71.973206,110.355202 
	C75.640045,123.324120 64.052650,135.701263 49.189774,134.446594 
	C45.587982,134.142548 42.062019,132.940292 38.134319,132.029907 
z"/>
<path fill="#8DC844" opacity="1.000000" stroke="none" 
	d="
M106.968124,29.879456 
	C103.778313,29.998873 100.837654,29.669601 98.181976,30.390364 
	C84.550957,34.089882 79.758202,48.871700 88.572563,60.045326 
	C92.088242,64.501999 94.805969,69.101013 94.385262,74.974480 
	C93.376778,89.054070 77.999367,96.283394 65.701111,88.485756 
	C53.096642,80.493950 48.471409,61.665386 55.264183,45.998680 
	C63.376595,27.288383 86.294991,18.526131 104.796532,27.115244 
	C106.027061,27.686506 107.034698,28.737883 108.146011,29.565943 
	C108.045303,29.660603 107.959564,29.792143 107.840118,29.840590 
	C107.690804,29.901152 107.513779,29.893373 106.968124,29.879456 
z"/>
<path fill="#5DB2E2" opacity="1.000000" stroke="none" 
	d="
M203.556320,90.516319 
	C212.476929,102.528160 214.727600,115.194664 209.127777,128.893265 
	C208.058090,131.509964 206.401031,133.886536 205.014969,136.373886 
	C204.586655,136.210342 204.158340,136.046799 203.730026,135.883240 
	C203.858170,134.382004 204.047104,132.883072 204.104279,131.379150 
	C204.399399,123.615616 201.622513,117.300804 194.850327,113.239151 
	C188.340805,109.335030 182.022186,110.649590 175.403122,113.785736 
	C171.090057,115.829300 165.944611,117.612198 161.356308,117.240067 
	C153.831894,116.629807 148.784149,111.624649 146.803833,104.083366 
	C144.693649,96.047440 147.806931,89.592331 153.608231,84.449829 
	C163.719482,75.486778 181.037033,74.457100 193.348053,82.056961 
	C196.978485,84.298111 200.030243,87.476624 203.556320,90.516319 
z"/>
<path fill="#3DB64D" opacity="1.000000" stroke="none" 
	d="
M17.901432,166.913788 
	C13.719345,156.926163 13.591513,147.282349 17.097822,137.991867 
	C18.749895,142.267929 19.740482,147.127060 22.280844,150.958694 
	C28.808401,160.804214 41.469658,161.759796 49.975422,153.588181 
	C51.055279,152.550751 52.019936,151.394501 53.073193,150.328094 
	C59.816078,143.500961 68.613838,142.339249 76.235527,147.256317 
	C83.591866,152.002167 86.373901,161.722626 82.824883,170.279587 
	C77.803146,182.387375 61.842300,190.644409 47.562485,188.555954 
	C34.024311,186.575958 24.298574,179.336197 17.901432,166.913788 
z"/>
<path fill="#5DB2E2" opacity="1.000000" stroke="none" 
	d="
M179.925934,52.262569 
	C180.861481,55.433968 181.669754,58.243374 182.478027,61.052780 
	C182.098724,61.254951 181.719437,61.457123 181.340134,61.659294 
	C180.473099,60.649815 179.622910,59.625263 178.736237,58.633339 
	C173.502686,52.778584 166.853607,50.673241 159.338547,52.208916 
	C152.338379,53.639378 148.294754,58.601524 146.180450,65.227875 
	C145.676178,66.808273 145.417816,68.469543 144.879379,70.036224 
	C141.832855,78.900528 134.041245,83.470642 124.447334,82.077911 
	C116.723122,80.956604 110.656395,73.717339 109.753593,65.367027 
	C107.982162,48.982498 122.545105,34.442585 136.747818,31.028488 
	C152.022400,27.356728 171.525894,33.695995 179.925934,52.262569 
z"/>
<path fill="#045279" opacity="1.000000" stroke="none" 
	d="
M132.221176,179.958374 
	C139.809082,196.121979 127.234642,221.790009 104.232719,225.064026 
	C87.093544,227.503571 74.457153,221.035721 65.656738,206.430862 
	C65.448433,206.085190 65.392761,205.647537 64.931335,204.211731 
	C67.075478,205.510681 68.396027,206.348602 69.751549,207.125473 
	C76.329567,210.895340 83.071938,211.259293 89.668053,207.437805 
	C95.867920,203.845901 98.272194,198.078156 98.117912,191.042480 
	C98.052658,188.067108 97.885262,184.995026 98.497841,182.123367 
	C99.987267,175.141235 105.717583,170.004639 112.532547,168.962418 
	C120.831047,167.693329 127.226631,171.176788 132.221176,179.958374 
z"/>
</svg>
                            </div>
                        </span>
                        <p className="leading-relaxed">
                            <span className="block font-bold text-gray-700">Planetta AI </span> Ask me some questions.
                        </p>
                    </div>

                    {messages.map((message, index) => {
                        return (
                            <div
                                key={index} 
                                className="flex gap-3 my-4 text-gray-600 text-sm flex-1"
                                >
                                <span
                                    className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8"
                                    >
                                    <div className="rounded-full bg-gray-100 border p-1">
                                        {message.role === "assistant"
                                            && <svg version="1.1" id="Layer_1"  x="0px" y="0px"
                                            width="100%" viewBox="0 0 226 241" enable-background="new 0 0 226 241" xmlSpace="preserve">
                                       <path fill="#FEFEFE" opacity="1.000000" stroke="none" 
                                           d="
                                       M126.000000,242.000000 
                                           C84.000000,242.000000 42.500004,242.000000 1.000005,242.000000 
                                           C1.000004,161.666672 1.000004,81.333351 1.000002,1.000016 
                                           C76.333321,1.000011 151.666641,1.000011 226.999969,1.000005 
                                           C226.999985,81.333321 226.999985,161.666641 227.000000,241.999969 
                                           C193.500000,242.000000 160.000000,242.000000 126.000000,242.000000 
                                       M146.491409,143.056503 
                                           C138.323257,154.376221 141.535645,166.300125 154.291306,172.002274 
                                           C155.201172,172.409012 156.140198,172.756256 157.028351,173.204987 
                                           C168.042816,178.769897 171.064713,191.895920 163.082077,201.349121 
                                           C160.357742,204.575348 156.362808,206.728622 153.062363,209.283905 
                                           C152.878571,209.158875 153.117432,209.461349 153.369751,209.472992 
                                           C170.644241,210.270645 185.703812,198.830826 190.815155,181.051498 
                                           C195.067581,166.259796 187.533859,148.258179 173.741028,140.253403 
                                           C164.583832,134.938950 154.820328,135.767014 146.491409,143.056503 
                                       M38.501186,132.151047 
                                           C42.062019,132.940292 45.587982,134.142548 49.189774,134.446594 
                                           C64.052650,135.701263 75.640045,123.324120 71.973206,110.355202 
                                           C69.393890,101.232620 62.087646,96.772316 50.553524,97.389252 
                                           C42.969215,97.794922 36.673557,95.509720 32.814285,88.639473 
                                           C28.857149,81.595016 29.560928,74.589531 33.753510,67.828003 
                                           C34.452866,66.700119 35.174725,65.586189 36.117153,64.102104 
                                           C35.026093,64.228966 34.669163,64.187592 34.394112,64.318382 
                                           C33.643753,64.675201 32.899849,65.061218 32.202660,65.511887 
                                           C20.712320,72.939301 14.270797,83.512314 14.481328,97.171646 
                                           C14.732654,113.477730 22.633041,125.271133 38.501186,132.151047 
                                       M107.348717,29.915096 
                                           C107.513779,29.893373 107.690804,29.901152 107.840118,29.840590 
                                           C107.959564,29.792143 108.045303,29.660603 108.146011,29.565945 
                                           C107.034698,28.737883 106.027061,27.686506 104.796532,27.115244 
                                           C86.294991,18.526131 63.376595,27.288383 55.264183,45.998680 
                                           C48.471409,61.665386 53.096642,80.493950 65.701111,88.485756 
                                           C77.999367,96.283394 93.376778,89.054070 94.385262,74.974480 
                                           C94.805969,69.101013 92.088242,64.501999 88.572563,60.045326 
                                           C79.758202,48.871700 84.550957,34.089882 98.181976,30.390364 
                                           C100.837654,29.669601 103.778313,29.998873 107.348717,29.915096 
                                       M203.347198,90.225571 
                                           C200.030243,87.476624 196.978485,84.298111 193.348053,82.056961 
                                           C181.037033,74.457100 163.719482,75.486778 153.608231,84.449829 
                                           C147.806931,89.592331 144.693649,96.047440 146.803833,104.083366 
                                           C148.784149,111.624649 153.831894,116.629807 161.356308,117.240067 
                                           C165.944611,117.612198 171.090057,115.829300 175.403122,113.785736 
                                           C182.022186,110.649590 188.340805,109.335030 194.850327,113.239151 
                                           C201.622513,117.300804 204.399399,123.615616 204.104279,131.379150 
                                           C204.047104,132.883072 203.858170,134.382004 203.730026,135.883240 
                                           C204.158340,136.046799 204.586655,136.210342 205.014969,136.373886 
                                           C206.401031,133.886536 208.058090,131.509964 209.127777,128.893265 
                                           C214.727600,115.194664 212.476929,102.528160 203.347198,90.225571 
                                       M18.043976,167.269455 
                                           C24.298574,179.336197 34.024311,186.575958 47.562485,188.555954 
                                           C61.842300,190.644409 77.803146,182.387375 82.824883,170.279587 
                                           C86.373901,161.722626 83.591866,152.002167 76.235527,147.256317 
                                           C68.613838,142.339249 59.816078,143.500961 53.073193,150.328094 
                                           C52.019936,151.394501 51.055279,152.550751 49.975422,153.588181 
                                           C41.469658,161.759796 28.808401,160.804214 22.280844,150.958694 
                                           C19.740482,147.127060 18.749895,142.267929 17.097822,137.991867 
                                           C13.591513,147.282349 13.719345,156.926163 18.043976,167.269455 
                                       M179.798660,51.900578 
                                           C171.525894,33.695995 152.022400,27.356728 136.747818,31.028488 
                                           C122.545105,34.442585 107.982162,48.982498 109.753593,65.367027 
                                           C110.656395,73.717339 116.723122,80.956604 124.447334,82.077911 
                                           C134.041245,83.470642 141.832855,78.900528 144.879379,70.036224 
                                           C145.417816,68.469543 145.676178,66.808273 146.180450,65.227875 
                                           C148.294754,58.601524 152.338379,53.639378 159.338547,52.208916 
                                           C166.853607,50.673241 173.502686,52.778584 178.736237,58.633339 
                                           C179.622910,59.625263 180.473099,60.649815 181.340134,61.659294 
                                           C181.719437,61.457123 182.098724,61.254951 182.478027,61.052780 
                                           C181.669754,58.243374 180.861481,55.433968 179.798660,51.900578 
                                       M132.070984,179.604385 
                                           C127.226631,171.176788 120.831047,167.693329 112.532547,168.962418 
                                           C105.717583,170.004639 99.987267,175.141235 98.497841,182.123367 
                                           C97.885262,184.995026 98.052658,188.067108 98.117912,191.042480 
                                           C98.272194,198.078156 95.867920,203.845901 89.668053,207.437805 
                                           C83.071938,211.259293 76.329567,210.895340 69.751549,207.125473 
                                           C68.396027,206.348602 67.075478,205.510681 64.931335,204.211731 
                                           C65.392761,205.647537 65.448433,206.085190 65.656738,206.430862 
                                           C74.457153,221.035721 87.093544,227.503571 104.232719,225.064026 
                                           C127.234642,221.790009 139.809082,196.121979 132.070984,179.604385 
                                       z"/>
                                       <path fill="#055476" opacity="1.000000" stroke="none" 
                                           d="
                                       M146.742737,142.789185 
                                           C154.820328,135.767014 164.583832,134.938950 173.741028,140.253403 
                                           C187.533859,148.258179 195.067581,166.259796 190.815155,181.051498 
                                           C185.703812,198.830826 170.644241,210.270645 153.369751,209.472992 
                                           C153.117432,209.461349 152.878571,209.158875 153.062363,209.283905 
                                           C156.362808,206.728622 160.357742,204.575348 163.082077,201.349121 
                                           C171.064713,191.895920 168.042816,178.769897 157.028351,173.204987 
                                           C156.140198,172.756256 155.201172,172.409012 154.291306,172.002274 
                                           C141.535645,166.300125 138.323257,154.376221 146.742737,142.789185 
                                       z"/>
                                       <path fill="#3DB64D" opacity="1.000000" stroke="none" 
                                           d="
                                       M38.134319,132.029907 
                                           C22.633041,125.271133 14.732654,113.477730 14.481328,97.171646 
                                           C14.270797,83.512314 20.712320,72.939301 32.202660,65.511887 
                                           C32.899849,65.061218 33.643753,64.675201 34.394112,64.318382 
                                           C34.669163,64.187592 35.026093,64.228966 36.117153,64.102104 
                                           C35.174725,65.586189 34.452866,66.700119 33.753510,67.828003 
                                           C29.560928,74.589531 28.857149,81.595016 32.814285,88.639473 
                                           C36.673557,95.509720 42.969215,97.794922 50.553524,97.389252 
                                           C62.087646,96.772316 69.393890,101.232620 71.973206,110.355202 
                                           C75.640045,123.324120 64.052650,135.701263 49.189774,134.446594 
                                           C45.587982,134.142548 42.062019,132.940292 38.134319,132.029907 
                                       z"/>
                                       <path fill="#8DC844" opacity="1.000000" stroke="none" 
                                           d="
                                       M106.968124,29.879456 
                                           C103.778313,29.998873 100.837654,29.669601 98.181976,30.390364 
                                           C84.550957,34.089882 79.758202,48.871700 88.572563,60.045326 
                                           C92.088242,64.501999 94.805969,69.101013 94.385262,74.974480 
                                           C93.376778,89.054070 77.999367,96.283394 65.701111,88.485756 
                                           C53.096642,80.493950 48.471409,61.665386 55.264183,45.998680 
                                           C63.376595,27.288383 86.294991,18.526131 104.796532,27.115244 
                                           C106.027061,27.686506 107.034698,28.737883 108.146011,29.565943 
                                           C108.045303,29.660603 107.959564,29.792143 107.840118,29.840590 
                                           C107.690804,29.901152 107.513779,29.893373 106.968124,29.879456 
                                       z"/>
                                       <path fill="#5DB2E2" opacity="1.000000" stroke="none" 
                                           d="
                                       M203.556320,90.516319 
                                           C212.476929,102.528160 214.727600,115.194664 209.127777,128.893265 
                                           C208.058090,131.509964 206.401031,133.886536 205.014969,136.373886 
                                           C204.586655,136.210342 204.158340,136.046799 203.730026,135.883240 
                                           C203.858170,134.382004 204.047104,132.883072 204.104279,131.379150 
                                           C204.399399,123.615616 201.622513,117.300804 194.850327,113.239151 
                                           C188.340805,109.335030 182.022186,110.649590 175.403122,113.785736 
                                           C171.090057,115.829300 165.944611,117.612198 161.356308,117.240067 
                                           C153.831894,116.629807 148.784149,111.624649 146.803833,104.083366 
                                           C144.693649,96.047440 147.806931,89.592331 153.608231,84.449829 
                                           C163.719482,75.486778 181.037033,74.457100 193.348053,82.056961 
                                           C196.978485,84.298111 200.030243,87.476624 203.556320,90.516319 
                                       z"/>
                                       <path fill="#3DB64D" opacity="1.000000" stroke="none" 
                                           d="
                                       M17.901432,166.913788 
                                           C13.719345,156.926163 13.591513,147.282349 17.097822,137.991867 
                                           C18.749895,142.267929 19.740482,147.127060 22.280844,150.958694 
                                           C28.808401,160.804214 41.469658,161.759796 49.975422,153.588181 
                                           C51.055279,152.550751 52.019936,151.394501 53.073193,150.328094 
                                           C59.816078,143.500961 68.613838,142.339249 76.235527,147.256317 
                                           C83.591866,152.002167 86.373901,161.722626 82.824883,170.279587 
                                           C77.803146,182.387375 61.842300,190.644409 47.562485,188.555954 
                                           C34.024311,186.575958 24.298574,179.336197 17.901432,166.913788 
                                       z"/>
                                       <path fill="#5DB2E2" opacity="1.000000" stroke="none" 
                                           d="
                                       M179.925934,52.262569 
                                           C180.861481,55.433968 181.669754,58.243374 182.478027,61.052780 
                                           C182.098724,61.254951 181.719437,61.457123 181.340134,61.659294 
                                           C180.473099,60.649815 179.622910,59.625263 178.736237,58.633339 
                                           C173.502686,52.778584 166.853607,50.673241 159.338547,52.208916 
                                           C152.338379,53.639378 148.294754,58.601524 146.180450,65.227875 
                                           C145.676178,66.808273 145.417816,68.469543 144.879379,70.036224 
                                           C141.832855,78.900528 134.041245,83.470642 124.447334,82.077911 
                                           C116.723122,80.956604 110.656395,73.717339 109.753593,65.367027 
                                           C107.982162,48.982498 122.545105,34.442585 136.747818,31.028488 
                                           C152.022400,27.356728 171.525894,33.695995 179.925934,52.262569 
                                       z"/>
                                       <path fill="#045279" opacity="1.000000" stroke="none" 
                                           d="
                                       M132.221176,179.958374 
                                           C139.809082,196.121979 127.234642,221.790009 104.232719,225.064026 
                                           C87.093544,227.503571 74.457153,221.035721 65.656738,206.430862 
                                           C65.448433,206.085190 65.392761,205.647537 64.931335,204.211731 
                                           C67.075478,205.510681 68.396027,206.348602 69.751549,207.125473 
                                           C76.329567,210.895340 83.071938,211.259293 89.668053,207.437805 
                                           C95.867920,203.845901 98.272194,198.078156 98.117912,191.042480 
                                           C98.052658,188.067108 97.885262,184.995026 98.497841,182.123367 
                                           C99.987267,175.141235 105.717583,170.004639 112.532547,168.962418 
                                           C120.831047,167.693329 127.226631,171.176788 132.221176,179.958374 
                                       z"/>
                                       </svg>
                                        }
                                        {message.role === "user"
                                            && <svg stroke="none" fill="black" strokeWidth="0"
                                                viewBox="0 0 16 16" height="20" width="20" xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z">
                                                </path>
                                            </svg>       
                                        }
                                    </div>
                                </span>
                                
                                <div className="flex flex-col">
                                    {message.content.map((content, index) => {
                                        if (content.type === "text") {
                                            return renderText(content.text.value, message.role);
                                        }
                                        else {
                                            return undefined;
                                        }
                                    })}
                                </div>
                                
                            </div>       
                        );
                    })}

                    {/* Cool progress indicators: https://loading.io/css/ */}
                    {runId !== undefined
                        && <div className="lds-ring">
                            <div></div><div></div><div></div><div></div>
                        </div>
                    }
                </div>

                {/* <!-- Input box  --> */}
                <div className="flex flex-col">
                    <div className="flex items-center pt-0">
                        <div className="flex items-center justify-center w-full space-x-2">
                            <input
                                className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                                placeholder="Ask a question." 
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                disabled={runId !== undefined}
                                onKeyDown={onKeyDown}
                                />

                            <button
                                className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-[#032B44] hover:bg-[#41b943e6] h-10 px-4 py-2"
                                onClick={onSendMessage}
                                disabled={runId !== undefined}
                                >
                                Send
                            </button>

                        </div>
                    </div>

                    <div className="text-sm ml-3 pt-3 pr-1 text-gray-500">
                        Example: What is your name?
                    </div>
                </div>

                <div 
                    className="absolute top-[10px] right-[10px] cursor-pointer"
                    onClick={onResetThread}
                    >
                    <svg width="24" height="24" viewBox="0 0 24 24">
                        <path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z"></path>
                    </svg>
                </div>
            </div>}

            <button
                className="fixed bottom-4 right-4 inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border rounded-full w-16 h-16 bg-[#032B44] hover:bg-gray-700 m-0 cursor-pointer border-gray-200 bg-none p-0 normal-case leading-5 hover:text-gray-900"
                type="button" aria-haspopup="dialog" aria-expanded="false" data-state="closed"
                onClick={onToggleChatbot}
                >
                <svg xmlns=" http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                    className="text-white block border-gray-200 align-middle">
                    <path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z" className="border-gray-200">
                    </path>
                </svg>
            </button>

            <Cv />
        </div>
    );
}